/*******************************
           Checkbox
*******************************/


/*--------------
    Content
---------------*/

.pq.checkbox,
.pq.checkbox * {
    box-sizing: border-box;
}

.pq.checkbox {
    position: relative;
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: none;
    vertical-align: baseline;
    font-style: normal;
    min-height: 17px;
    font-size: 1rem;
    line-height: 17px;
    min-width: 17px;
}


/* HTML Checkbox */

.pq.checkbox input[type="checkbox"],
.pq.checkbox input[type="radio"] {
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0 !important;
    outline: none;
    z-index: 3;
    width: 17px;
    height: 17px;
}


/*--------------
      Box
---------------*/

.pq.checkbox .box,
.pq.checkbox label {
    cursor: auto;
    position: relative;
    display: block;
    padding-left: 1.85714em;
    outline: none;
    font-size: 1em;
}

.pq.checkbox .box:before,
.pq.checkbox label:before {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 17px;
    height: 17px;
    content: '';
    background: #FFFFFF;
    border-radius: 0.21428571rem;
    -webkit-transition: border 0.1s ease, opacity 0.1s ease, -webkit-transform 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: border 0.1s ease, opacity 0.1s ease, -webkit-transform 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
    transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease, -webkit-transform 0.1s ease, -webkit-box-shadow 0.1s ease;
    border: 1px solid #D4D4D5;
}


/*--------------
    Checkmark
---------------*/

.pq.checkbox .box:after,
.pq.checkbox label:after {
    position: absolute;
    font-size: 14px;
    top: 0px;
    left: 0px;
    width: 17px;
    height: 17px;
    text-align: center;
    opacity: 0;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: border 0.1s ease, opacity 0.1s ease, -webkit-transform 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: border 0.1s ease, opacity 0.1s ease, -webkit-transform 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
    transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease, -webkit-transform 0.1s ease, -webkit-box-shadow 0.1s ease;
}


/*--------------
      Label
---------------*/


/* Inside */

.pq.checkbox label,
.pq.checkbox+label {
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: color 0.1s ease;
    transition: color 0.1s ease;
}


/* Outside */

.pq.checkbox+label {
    vertical-align: middle;
}


/*******************************
           States
*******************************/


/*--------------
      Hover
---------------*/

.pq.checkbox .box:hover::before,
.pq.checkbox label:hover::before {
    background: #FFFFFF;
    border-color: rgba(34, 36, 38, 0.35);
}

.pq.checkbox label:hover,
.pq.checkbox+label:hover {
    color: rgba(0, 0, 0, 0.8);
}


/*--------------
      Down
---------------*/

.pq.checkbox .box:active::before,
.pq.checkbox label:active::before {
    background: #F9FAFB;
    border-color: rgba(34, 36, 38, 0.35);
}

.pq.checkbox .box:active::after,
.pq.checkbox label:active::after {
    color: rgba(0, 0, 0, 0.95);
}

.pq.checkbox input:active~label {
    color: rgba(0, 0, 0, 0.95);
}


/*--------------
     Focus
---------------*/

.pq.checkbox input:focus~.box:before,
.pq.checkbox input:focus~label:before {
    background: #FFFFFF;
    border-color: #96C8DA;
}

.pq.checkbox input:focus~.box:after,
.pq.checkbox input:focus~label:after {
    color: rgba(0, 0, 0, 0.95);
}

.pq.checkbox input:focus~label {
    color: rgba(0, 0, 0, 0.95);
}


/*--------------
     Active
---------------*/

.pq.checkbox input:checked~.box:before,
.pq.checkbox input:checked~label:before {
    background: #FFFFFF;
    border-color: rgba(34, 36, 38, 0.35);
}

.pq.checkbox input:checked~.box:after,
.pq.checkbox input:checked~label:after {
    opacity: 1;
    color: rgba(0, 0, 0, 0.95);
}


/*--------------
  Indeterminate
---------------*/

.pq.checkbox input:not([type=radio]):indeterminate~.box:before,
.pq.checkbox input:not([type=radio]):indeterminate~label:before {
    background: #FFFFFF;
    border-color: rgba(34, 36, 38, 0.35);
}

.pq.checkbox input:not([type=radio]):indeterminate~.box:after,
.pq.checkbox input:not([type=radio]):indeterminate~label:after {
    opacity: 1;
    color: rgba(0, 0, 0, 0.95);
}


/*--------------
  Active Focus
---------------*/

.pq.checkbox input:not([type=radio]):indeterminate:focus~.box:before,
.pq.checkbox input:not([type=radio]):indeterminate:focus~label:before,
.pq.checkbox input:checked:focus~.box:before,
.pq.checkbox input:checked:focus~label:before {
    background: #FFFFFF;
    border-color: #96C8DA;
}

.pq.checkbox input:not([type=radio]):indeterminate:focus~.box:after,
.pq.checkbox input:not([type=radio]):indeterminate:focus~label:after,
.pq.checkbox input:checked:focus~.box:after,
.pq.checkbox input:checked:focus~label:after {
    color: rgba(0, 0, 0, 0.95);
}


/*--------------
    Read-Only
---------------*/

.pq.read-only.checkbox,
.pq.read-only.checkbox label {
    cursor: default;
}


/*--------------
     Disabled
---------------*/

.pq.disabled.checkbox .box:after,
.pq.disabled.checkbox label,
.pq.checkbox input[disabled]~.box:after,
.pq.checkbox input[disabled]~label {
    cursor: default !important;
    opacity: 0.5;
    color: #000000;
}


/*--------------
     Hidden
---------------*/


/* Initialized checkbox moves input below element
 to prevent manually triggering */

.pq.checkbox input.hidden {
    z-index: -1;
}


/* Selectable Label */

.pq.checkbox input.hidden+label {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/*******************************
             Types
*******************************/


/*--------------
     Radio
---------------*/

.pq.radio.checkbox {
    min-height: 15px;
}

.pq.radio.checkbox .box,
.pq.radio.checkbox label {
    padding-left: 1.85714em;
}


/* Box */

.pq.radio.checkbox .box:before,
.pq.radio.checkbox label:before {
    content: '';
    -webkit-transform: none;
    transform: none;
    width: 15px;
    height: 15px;
    border-radius: 500rem;
    top: 1px;
    left: 0px;
}


/* Bullet */

.pq.radio.checkbox .box:after,
.pq.radio.checkbox label:after {
    border: none;
    content: '' !important;
    width: 15px;
    height: 15px;
    line-height: 15px;
}


/* Radio Checkbox */

.pq.radio.checkbox .box:after,
.pq.radio.checkbox label:after {
    top: 1px;
    left: 0px;
    width: 15px;
    height: 15px;
    border-radius: 500rem;
    -webkit-transform: scale(0.46666667);
    transform: scale(0.46666667);
    background-color: rgba(0, 0, 0, 0.87);
}


/* Focus */

.pq.radio.checkbox input:focus~.box:before,
.pq.radio.checkbox input:focus~label:before {
    background-color: #FFFFFF;
}

.pq.radio.checkbox input:focus~.box:after,
.pq.radio.checkbox input:focus~label:after {
    background-color: rgba(0, 0, 0, 0.95);
}


/* Indeterminate */

.pq.radio.checkbox input:indeterminate~.box:after,
.pq.radio.checkbox input:indeterminate~label:after {
    opacity: 0;
}


/* Active */

.pq.radio.checkbox input:checked~.box:before,
.pq.radio.checkbox input:checked~label:before {
    background-color: #FFFFFF;
}

.pq.radio.checkbox input:checked~.box:after,
.pq.radio.checkbox input:checked~label:after {
    background-color: rgba(0, 0, 0, 0.95);
}


/* Active Focus */

.pq.radio.checkbox input:focus:checked~.box:before,
.pq.radio.checkbox input:focus:checked~label:before {
    background-color: #FFFFFF;
}

.pq.radio.checkbox input:focus:checked~.box:after,
.pq.radio.checkbox input:focus:checked~label:after {
    background-color: rgba(0, 0, 0, 0.95);
}


/*--------------
     Slider
---------------*/

.pq.slider.checkbox {
    min-height: 1.25rem;
}


/* Input */

.pq.slider.checkbox input {
    width: 3.5rem;
    height: 1.25rem;
}


/* Label */

.pq.slider.checkbox .box,
.pq.slider.checkbox label {
    padding-left: 4.5rem;
    line-height: 1rem;
    color: rgba(0, 0, 0, 0.4);
}


/* Line */

.pq.slider.checkbox .box:before,
.pq.slider.checkbox label:before {
    display: block;
    position: absolute;
    content: '';
    border: none !important;
    left: 0em;
    z-index: 1;
    top: 0.4rem;
    background-color: rgba(0, 0, 0, 0.05);
    width: 3.5rem;
    height: 0.21428571rem;
    -webkit-transform: none;
    transform: none;
    border-radius: 500rem;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
}


/* Handle */

.pq.slider.checkbox .box:after,
.pq.slider.checkbox label:after {
    background: #FFFFFF -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.05)));
    background: #FFFFFF -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
    background: #FFFFFF linear-gradient(transparent, rgba(0, 0, 0, 0.05));
    position: absolute;
    content: '' !important;
    opacity: 1;
    z-index: 2;
    border: none;
    -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
    width: 1.5rem;
    height: 1.5rem;
    top: -0.25rem;
    left: 0em;
    -webkit-transform: none;
    transform: none;
    border-radius: 500rem;
    -webkit-transition: left 0.3s ease;
    transition: left 0.3s ease;
}


/* Focus */

.pq.slider.checkbox input:focus~.box:before,
.pq.slider.checkbox input:focus~label:before {
    background-color: rgba(0, 0, 0, 0.15);
    border: none;
}


/* Hover */

.pq.slider.checkbox .box:hover,
.pq.slider.checkbox label:hover {
    color: rgba(0, 0, 0, 0.8);
}

.pq.slider.checkbox .box:hover::before,
.pq.slider.checkbox label:hover::before {
    background: rgba(0, 0, 0, 0.15);
}


/* Active */

.pq.slider.checkbox input:checked~.box,
.pq.slider.checkbox input:checked~label {
    color: rgba(0, 0, 0, 0.95) !important;
}

.pq.slider.checkbox input:checked~.box:before,
.pq.slider.checkbox input:checked~label:before {
    background-color: #545454 !important;
}

.pq.slider.checkbox input:checked~.box:after,
.pq.slider.checkbox input:checked~label:after {
    left: 2rem;
}


/* Active Focus */

.pq.slider.checkbox input:focus:checked~.box,
.pq.slider.checkbox input:focus:checked~label {
    color: rgba(0, 0, 0, 0.95) !important;
}

.pq.slider.checkbox input:focus:checked~.box:before,
.pq.slider.checkbox input:focus:checked~label:before {
    background-color: #000000 !important;
}


/*--------------
     Toggle
---------------*/

.pq.toggle.checkbox {
    min-height: 1.5rem;
}


/* Input */

.pq.toggle.checkbox input {
    width: 3.5rem;
    height: 1.5rem;
}


/* Label */

.pq.toggle.checkbox .box,
.pq.toggle.checkbox label {
    min-height: 1.5rem;
    padding-left: 4.5rem;
    color: rgba(0, 0, 0, 0.87);
}

.pq.toggle.checkbox label {
    padding-top: 0.15em;
}


/* Switch */

.pq.toggle.checkbox .box:before,
.pq.toggle.checkbox label:before {
    display: block;
    position: absolute;
    content: '';
    z-index: 1;
    -webkit-transform: none;
    transform: none;
    border: none;
    top: 0rem;
    background: rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 3.5rem;
    height: 1.5rem;
    border-radius: 500rem;
}


/* Handle */

.pq.toggle.checkbox .box:after,
.pq.toggle.checkbox label:after {
    background: #FFFFFF -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.05)));
    background: #FFFFFF -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
    background: #FFFFFF linear-gradient(transparent, rgba(0, 0, 0, 0.05));
    position: absolute;
    content: '' !important;
    opacity: 1;
    z-index: 2;
    border: none;
    -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
    width: 1.5rem;
    height: 1.5rem;
    top: 0rem;
    left: 0em;
    border-radius: 500rem;
    -webkit-transition: background 0.3s ease, left 0.3s ease;
    transition: background 0.3s ease, left 0.3s ease;
}

.pq.toggle.checkbox input~.box:after,
.pq.toggle.checkbox input~label:after {
    left: -0.05rem;
    -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}


/* Focus */

.pq.toggle.checkbox input:focus~.box:before,
.pq.toggle.checkbox input:focus~label:before {
    background-color: rgba(0, 0, 0, 0.15);
    border: none;
}


/* Hover */

.pq.toggle.checkbox .box:hover::before,
.pq.toggle.checkbox label:hover::before {
    background-color: rgba(0, 0, 0, 0.15);
    border: none;
}


/* Active */

.pq.toggle.checkbox input:checked~.box,
.pq.toggle.checkbox input:checked~label {
    color: rgba(0, 0, 0, 0.95) !important;
}

.pq.toggle.checkbox input:checked~.box:before,
.pq.toggle.checkbox input:checked~label:before {
    background-color: #2185D0 !important;
}

.pq.toggle.checkbox input:checked~.box:after,
.pq.toggle.checkbox input:checked~label:after {
    left: 2.15rem;
    -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}


/* Active Focus */

.pq.toggle.checkbox input:focus:checked~.box,
.pq.toggle.checkbox input:focus:checked~label {
    color: rgba(0, 0, 0, 0.95) !important;
}

.pq.toggle.checkbox input:focus:checked~.box:before,
.pq.toggle.checkbox input:focus:checked~label:before {
    background-color: #0d71bb !important;
}


/*******************************
            Variations
*******************************/


/*--------------
     Fitted
---------------*/

.pq.fitted.checkbox .box,
.pq.fitted.checkbox label {
    padding-left: 0em !important;
}

.pq.fitted.toggle.checkbox,
.pq.fitted.toggle.checkbox {
    width: 3.5rem;
}

.pq.fitted.slider.checkbox,
.pq.fitted.slider.checkbox {
    width: 3.5rem;
}


/*******************************
         Theme Overrides
*******************************/

@font-face {
    font-family: 'Checkbox';
    src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg8SBD8AAAC8AAAAYGNtYXAYVtCJAAABHAAAAFRnYXNwAAAAEAAAAXAAAAAIZ2x5Zn4huwUAAAF4AAABYGhlYWQGPe1ZAAAC2AAAADZoaGVhB30DyAAAAxAAAAAkaG10eBBKAEUAAAM0AAAAHGxvY2EAmgESAAADUAAAABBtYXhwAAkALwAAA2AAAAAgbmFtZSC8IugAAAOAAAABknBvc3QAAwAAAAAFFAAAACAAAwMTAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADoAgPA/8AAQAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAADAAAAAwAAABwAAQADAAAAHAADAAEAAAAcAAQAOAAAAAoACAACAAIAAQAg6AL//f//AAAAAAAg6AD//f//AAH/4xgEAAMAAQAAAAAAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAAAAAAAAAAIAADc5AQAAAAABAEUAUQO7AvgAGgAAARQHAQYjIicBJjU0PwE2MzIfAQE2MzIfARYVA7sQ/hQQFhcQ/uMQEE4QFxcQqAF2EBcXEE4QAnMWEP4UEBABHRAXFhBOEBCoAXcQEE4QFwAAAAABAAABbgMlAkkAFAAAARUUBwYjISInJj0BNDc2MyEyFxYVAyUQEBf9SRcQEBAQFwK3FxAQAhJtFxAQEBAXbRcQEBAQFwAAAAABAAAASQMlA24ALAAAARUUBwYrARUUBwYrASInJj0BIyInJj0BNDc2OwE1NDc2OwEyFxYdATMyFxYVAyUQEBfuEBAXbhYQEO4XEBAQEBfuEBAWbhcQEO4XEBACEm0XEBDuFxAQEBAX7hAQF20XEBDuFxAQEBAX7hAQFwAAAQAAAAIAAHRSzT9fDzz1AAsEAAAAAADRsdR3AAAAANGx1HcAAAAAA7sDbgAAAAgAAgAAAAAAAAABAAADwP/AAAAEAAAAAAADuwABAAAAAAAAAAAAAAAAAAAABwQAAAAAAAAAAAAAAAIAAAAEAABFAyUAAAMlAAAAAAAAAAoAFAAeAE4AcgCwAAEAAAAHAC0AAQAAAAAAAgAAAAAAAAAAAAAAAAAAAAAAAAAOAK4AAQAAAAAAAQAIAAAAAQAAAAAAAgAHAGkAAQAAAAAAAwAIADkAAQAAAAAABAAIAH4AAQAAAAAABQALABgAAQAAAAAABgAIAFEAAQAAAAAACgAaAJYAAwABBAkAAQAQAAgAAwABBAkAAgAOAHAAAwABBAkAAwAQAEEAAwABBAkABAAQAIYAAwABBAkABQAWACMAAwABBAkABgAQAFkAAwABBAkACgA0ALBDaGVja2JveABDAGgAZQBjAGsAYgBvAHhWZXJzaW9uIDIuMABWAGUAcgBzAGkAbwBuACAAMgAuADBDaGVja2JveABDAGgAZQBjAGsAYgBvAHhDaGVja2JveABDAGgAZQBjAGsAYgBvAHhSZWd1bGFyAFIAZQBnAHUAbABhAHJDaGVja2JveABDAGgAZQBjAGsAYgBvAHhGb250IGdlbmVyYXRlZCBieSBJY29Nb29uLgBGAG8AbgB0ACAAZwBlAG4AZQByAGEAdABlAGQAIABiAHkAIABJAGMAbwBNAG8AbwBuAC4AAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA) format('truetype');
}


/* Checkmark */

.pq.checkbox label:after,
.pq.checkbox .box:after {
    font-family: 'Checkbox';
}


/* Checked */

.pq.checkbox input:checked~.box:after,
.pq.checkbox input:checked~label:after {
    content: '\e800';
}


/* Indeterminate */

.pq.checkbox input:indeterminate~.box:after,
.pq.checkbox input:indeterminate~label:after {
    font-size: 12px;
    content: '\e801';
}


/*  UTF Reference
.check:before { content: '\e800'; }
.dash:before  { content: '\e801'; }
.plus:before { content: '\e802'; }
*/


/*******************************
         Site Overrides
*******************************/