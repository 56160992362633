.pure_css_animated_background {
    width: 100%;
    padding-top: 60px;
    background: black;
    overflow: hidden;
    position: relative;
    color: white;
    transition: transform 0.4s;
}

.pure_css_animated_background .text-title {
    display: flex;
    align-items: center;
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    min-height: 200px;
    padding: 40px 5%;
    line-height: 150%;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.pure_css_animated_background .text-title .text-title-inner {
    width: 100%;
    flex: 1;
}

.pure_css_animated_background .text-category {
    text-align: center;
    font-weight: bold;
    min-height: 200px;
    padding: 40px 5%;
    line-height: 150%;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.pure_css_animated_background .text-category .title {
    display: flex;
    align-items: center;
    font-size: 36px;
    line-height: 150%;
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.pure_css_animated_background .text-category .intro {
    display: flex;
    align-items: center;
    line-height: 18px;
    font-size: 18px;
    line-height: 150%;
    font-weight: normal;
    display: block;
    width: 100%;
}

.pure_css_animated_background>span.sprite {
    position: absolute;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.3);
    /*background: rgba(0, 0, 0, 0.2);*/
    bottom: -150px;
    display: inline-block;
    font-size: 40px;
}

.pure_css_animated_background>span.sprite.animate {
    animation: pure_css_animated_background_animate 50s linear infinite;
}

.pure_css_animated_background>span.sprite.n1 {
    left: 75%;
    width: 1em;
    height: 1em;
    animation-delay: calc(-10s - 0.04s * var(--html-scrollTop));
    animation-duration: 30s;
    transform: translateY(-400px) rotate(45deg);
    opacity: 1;
    border-radius: 20%;
}

.pure_css_animated_background>span.sprite.n2 {
    left: 18%;
    max-width: 20px;
    max-height: 20px;
    width: 0.5em;
    height: 0.5em;
    animation-delay: calc(-10s - 0.04s * var(--html-scrollTop));
    animation-duration: 35s;
    transform: translateY(-200px) rotate(60deg);
    opacity: 0.7;
    border-radius: 50%;
}

.pure_css_animated_background>span.sprite.n3 {
    left: 70%;
    max-width: 20px;
    max-height: 20px;
    width: 0.5em;
    height: 0.5em;
    animation-delay: calc(-14s - 0.04s * var(--html-scrollTop));
    animation-duration: 20s;
    transform: translateY(-300px) rotate(20deg);
    opacity: 0.4;
    border-radius: 5%;
}

.pure_css_animated_background>span.sprite.n4 {
    left: 27%;
    max-width: 40px;
    max-height: 40px;
    width: 1em;
    height: 1em;
    animation-delay: calc(-20s - 0.04s * var(--html-scrollTop));
    animation-duration: 30s;
    transform: translateY(-130px) rotate(20deg);
    opacity: 0.8;
    border-radius: 20%;
}

.pure_css_animated_background>span.sprite.n5 {
    left: 45%;
    max-width: 34px;
    max-height: 34px;
    width: 0.8em;
    height: 0.8em;
    animation-delay: calc(-22s - 0.04s * var(--html-scrollTop));
    animation-duration: 30s;
    transform: translateY(-590px) rotate(20deg);
    opacity: 1;
    border-radius: 30%;
}

.pure_css_animated_background>span.sprite.n6 {
    left: 56%;
    max-width: 60px;
    max-height: 60px;
    width: 1.5em;
    height: 1.5em;
    animation-delay: calc(-14s - 0.04s * var(--html-scrollTop));
    animation-duration: 20s;
    transform: translateY(-150px) rotate(50deg);
    opacity: 0.6;
    border-radius: 0%;
}

.pure_css_animated_background>span.sprite.n7 {
    left: 5%;
    max-width: 90px;
    max-height: 90px;
    width: 2.25em;
    height: 2.25em;
    animation-delay: calc(-10s - 0.04s * var(--html-scrollTop));
    animation-duration: 20s;
    transform: translateY(-310px) rotate(190deg);
    opacity: 1;
    border-radius: 10%;
}

.pure_css_animated_background>span.sprite.n8 {
    left: 50%;
    max-width: 24px;
    max-height: 24px;
    width: 0.6em;
    height: 0.6em;
    animation-delay: calc(-14s - 0.04s * var(--html-scrollTop));
    animation-duration: 25s;
    transform: translateY(0px) rotate(190deg);
    opacity: 1;
    border-radius: 10%;
}

.pure_css_animated_background>span.sprite.n9 {
    left: 20%;
    max-width: 16px;
    max-height: 16px;
    width: 0.4em;
    height: 0.4em;
    animation-delay: calc(-12s - 0.04s * var(--html-scrollTop));
    animation-duration: 35s;
    transform: translateY(-50px) rotate(190deg);
    opacity: 1;
    border-radius: 10%;
}

.pure_css_animated_background>span.sprite.n10 {
    left: 85%;
    max-width: 110px;
    max-height: 110px;
    width: 2.75em;
    height: 2.75em;
    animation-delay: calc(-10s - 0.04s * var(--html-scrollTop));
    animation-duration: 20s;
    transform: translateY(-90px) rotate(190deg);
    opacity: 1;
    border-radius: 50%;
}

.pure_css_animated_background>span.sprite.n11 {
    left: 37%;
    max-width: 30px;
    max-height: 30px;
    width: 0.75em;
    height: 0.75em;
    animation-delay: calc(-1s - 0.04s * var(--html-scrollTop));
    animation-duration: 20s;
    transform: translateY(-90px) rotate(190deg);
    opacity: 1;
    border-radius: 50%;
}

.pure_css_animated_background>span.sprite.n12 {
    left: 64%;
    max-width: 40px;
    max-height: 40px;
    width: 1em;
    height: 1em;
    animation-delay: calc(-5s - 0.04s * var(--html-scrollTop));
    animation-duration: 20s;
    transform: translateY(-90px) rotate(190deg);
    opacity: 1;
    border-radius: 50%;
}

@keyframes pure_css_animated_background_animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 0.8;
        border-radius: 0;
    }
    100% {
        transform: translateY(-600px) rotate(360deg);
        opacity: 0.2;
        border-radius: 50%;
    }
}


/* PC端样式 */

@media screen and (min-width: 841px) {
    .pure_css_animated_background .text-category {
        
    }
}


/* Pad样式 */

@media screen and (max-width: 840px) {
    .pure_css_animated_background {}
    .pure_css_animated_background .text-title,
    .pure_css_animated_background .text-category {
        font-size: 32px;
        height: auto;
        min-height: 100px;
        line-height: 150%;
        padding: 40px 20px;
    }
    .pure_css_animated_background .text-category .intro {
        font-size: 16px;
    }
    .pure_css_animated_background>span.sprite {
        font-size: 30px;
    }
    .pq-layout.mobile-nav-on .pure_css_animated_background {
        transform: translateX(260px);
    }
}

@media screen and (max-width: 640px) {
    .pure_css_animated_background {}
    .pure_css_animated_background .text-title,
    .pure_css_animated_background .text-category {
        font-size: 24px;
        padding: 30px;
    }
    .pure_css_animated_background .text-category .intro {
        font-size: 14px;
    }
    .pure_css_animated_background>span.sprite {
        font-size: 24px;
    }
}


/* Mobile样式 */

@media screen and (max-width: 440px) {
    .pure_css_animated_background {}
    .pure_css_animated_background .text-title,
    .pure_css_animated_background .text-category {
        font-size: 18px;
        padding: 20px;
    }
    .pure_css_animated_background>span.sprite {
        font-size: 20px;
    }
}