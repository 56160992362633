/*******************************
            Standard
*******************************/

.pq.grid *,
.pq.grid *:before,
.pq.grid *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.pq.grid {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.pq.grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 0em;
}


/*----------------------
      Remove Gutters
-----------------------*/

.pq.grid {
    margin-top: -1rem;
    margin-bottom: -1rem;
    margin-left: -1rem;
    margin-right: -1rem;
}

.pq.relaxed.grid {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

.pq[class*="very relaxed"].grid {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
}


/* Preserve Rows Spacing on Consecutive Grids */

.pq.grid+.grid {
    margin-top: 1rem;
}


/*-------------------
       Columns
--------------------*/


/* Standard 16 column */

.pq.grid>.column:not(.row),
.pq.grid>.row>.column {
    position: relative;
    display: inline-block;
    width: 6.25%;
    padding-left: 1rem;
    padding-right: 1rem;
    vertical-align: top;
}

.pq.grid>* {
    padding-left: 1rem;
    padding-right: 1rem;
}


/*-------------------
        Rows
--------------------*/

.pq.grid>.row {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100% !important;
    padding: 0rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}


/*-------------------
       Columns
--------------------*/


/* Vertical padding when no rows */

.pq.grid>.column:not(.row) {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.pq.grid>.row>.column {
    margin-top: 0em;
    margin-bottom: 0em;
}


/*-------------------
      Content
--------------------*/

.pq.grid>.row>img,
.pq.grid>.row>.column>img {
    max-width: 100%;
}


/*-------------------
    Loose Coupling
--------------------*/


/* Collapse Margin on Consecutive Grid */

.pq.grid>.pq.grid:first-child {
    margin-top: 0em;
}

.pq.grid>.pq.grid:last-child {
    margin-bottom: 0em;
}


/* Segment inside Aligned Grid */

.pq.grid .aligned.row>.column>.segment:not(.compact):not(.attached),
.pq.aligned.grid .column>.segment:not(.compact):not(.attached) {
    width: 100%;
}


/* Align Dividers with Gutter */

.pq.grid .row+.pq.divider {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 1rem 1rem;
}

.pq.grid .column+.pq.vertical.divider {
    height: calc(50% - (2rem / 2));
}


/* Remove Border on Last Horizontal Segment */

.pq.grid>.row>.column:last-child>.horizontal.segment,
.pq.grid>.column:last-child>.horizontal.segment {
    -webkit-box-shadow: none;
    box-shadow: none;
}


/*******************************
           Variations
*******************************/


/*-----------------------
       Page Grid
-------------------------*/

@media only screen and (max-width: 767px) {
    .pq.page.grid {
        width: auto;
        padding-left: 0em;
        padding-right: 0em;
        margin-left: 0em;
        margin-right: 0em;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pq.page.grid {
        width: auto;
        margin-left: 0em;
        margin-right: 0em;
        padding-left: 2em;
        padding-right: 2em;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .pq.page.grid {
        width: auto;
        margin-left: 0em;
        margin-right: 0em;
        padding-left: 3%;
        padding-right: 3%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
    .pq.page.grid {
        width: auto;
        margin-left: 0em;
        margin-right: 0em;
        padding-left: 15%;
        padding-right: 15%;
    }
}

@media only screen and (min-width: 1920px) {
    .pq.page.grid {
        width: auto;
        margin-left: 0em;
        margin-right: 0em;
        padding-left: 23%;
        padding-right: 23%;
    }
}


/*-------------------
     Column Count
--------------------*/


/* Assume full width with one column */

.pq.grid>.column:only-child,
.pq.grid>.row>.column:only-child {
    width: 100%;
}


/* Grid Based */

.pq[class*="one column"].grid>.row>.column,
.pq[class*="one column"].grid>.column:not(.row) {
    width: 100%;
}

.pq[class*="two column"].grid>.row>.column,
.pq[class*="two column"].grid>.column:not(.row) {
    width: 50%;
}

.pq[class*="three column"].grid>.row>.column,
.pq[class*="three column"].grid>.column:not(.row) {
    width: 33.33333333%;
}

.pq[class*="four column"].grid>.row>.column,
.pq[class*="four column"].grid>.column:not(.row) {
    width: 25%;
}

.pq[class*="five column"].grid>.row>.column,
.pq[class*="five column"].grid>.column:not(.row) {
    width: 20%;
}

.pq[class*="six column"].grid>.row>.column,
.pq[class*="six column"].grid>.column:not(.row) {
    width: 16.66666667%;
}

.pq[class*="seven column"].grid>.row>.column,
.pq[class*="seven column"].grid>.column:not(.row) {
    width: 14.28571429%;
}

.pq[class*="eight column"].grid>.row>.column,
.pq[class*="eight column"].grid>.column:not(.row) {
    width: 12.5%;
}

.pq[class*="nine column"].grid>.row>.column,
.pq[class*="nine column"].grid>.column:not(.row) {
    width: 11.11111111%;
}

.pq[class*="ten column"].grid>.row>.column,
.pq[class*="ten column"].grid>.column:not(.row) {
    width: 10%;
}

.pq[class*="eleven column"].grid>.row>.column,
.pq[class*="eleven column"].grid>.column:not(.row) {
    width: 9.09090909%;
}

.pq[class*="twelve column"].grid>.row>.column,
.pq[class*="twelve column"].grid>.column:not(.row) {
    width: 8.33333333%;
}

.pq[class*="thirteen column"].grid>.row>.column,
.pq[class*="thirteen column"].grid>.column:not(.row) {
    width: 7.69230769%;
}

.pq[class*="fourteen column"].grid>.row>.column,
.pq[class*="fourteen column"].grid>.column:not(.row) {
    width: 7.14285714%;
}

.pq[class*="fifteen column"].grid>.row>.column,
.pq[class*="fifteen column"].grid>.column:not(.row) {
    width: 6.66666667%;
}

.pq[class*="sixteen column"].grid>.row>.column,
.pq[class*="sixteen column"].grid>.column:not(.row) {
    width: 6.25%;
}


/* Row Based Overrides */

.pq.grid>[class*="one column"].row>.column {
    width: 100% !important;
}

.pq.grid>[class*="two column"].row>.column {
    width: 50% !important;
}

.pq.grid>[class*="three column"].row>.column {
    width: 33.33333333% !important;
}

.pq.grid>[class*="four column"].row>.column {
    width: 25% !important;
}

.pq.grid>[class*="five column"].row>.column {
    width: 20% !important;
}

.pq.grid>[class*="six column"].row>.column {
    width: 16.66666667% !important;
}

.pq.grid>[class*="seven column"].row>.column {
    width: 14.28571429% !important;
}

.pq.grid>[class*="eight column"].row>.column {
    width: 12.5% !important;
}

.pq.grid>[class*="nine column"].row>.column {
    width: 11.11111111% !important;
}

.pq.grid>[class*="ten column"].row>.column {
    width: 10% !important;
}

.pq.grid>[class*="eleven column"].row>.column {
    width: 9.09090909% !important;
}

.pq.grid>[class*="twelve column"].row>.column {
    width: 8.33333333% !important;
}

.pq.grid>[class*="thirteen column"].row>.column {
    width: 7.69230769% !important;
}

.pq.grid>[class*="fourteen column"].row>.column {
    width: 7.14285714% !important;
}

.pq.grid>[class*="fifteen column"].row>.column {
    width: 6.66666667% !important;
}

.pq.grid>[class*="sixteen column"].row>.column {
    width: 6.25% !important;
}


/* Celled Page */

.pq.celled.page.grid {
    -webkit-box-shadow: none;
    box-shadow: none;
}


/*-------------------
    Column Width
--------------------*/


/* Sizing Combinations */

.pq.grid>.row>[class*="one wide"].column,
.pq.grid>.column.row>[class*="one wide"].column,
.pq.grid>[class*="one wide"].column,
.pq.column.grid>[class*="one wide"].column {
    width: 6.25% !important;
}

.pq.grid>.row>[class*="two wide"].column,
.pq.grid>.column.row>[class*="two wide"].column,
.pq.grid>[class*="two wide"].column,
.pq.column.grid>[class*="two wide"].column {
    width: 12.5% !important;
}

.pq.grid>.row>[class*="three wide"].column,
.pq.grid>.column.row>[class*="three wide"].column,
.pq.grid>[class*="three wide"].column,
.pq.column.grid>[class*="three wide"].column {
    width: 18.75% !important;
}

.pq.grid>.row>[class*="four wide"].column,
.pq.grid>.column.row>[class*="four wide"].column,
.pq.grid>[class*="four wide"].column,
.pq.column.grid>[class*="four wide"].column {
    width: 25% !important;
}

.pq.grid>.row>[class*="five wide"].column,
.pq.grid>.column.row>[class*="five wide"].column,
.pq.grid>[class*="five wide"].column,
.pq.column.grid>[class*="five wide"].column {
    width: 31.25% !important;
}

.pq.grid>.row>[class*="six wide"].column,
.pq.grid>.column.row>[class*="six wide"].column,
.pq.grid>[class*="six wide"].column,
.pq.column.grid>[class*="six wide"].column {
    width: 37.5% !important;
}

.pq.grid>.row>[class*="seven wide"].column,
.pq.grid>.column.row>[class*="seven wide"].column,
.pq.grid>[class*="seven wide"].column,
.pq.column.grid>[class*="seven wide"].column {
    width: 43.75% !important;
}

.pq.grid>.row>[class*="eight wide"].column,
.pq.grid>.column.row>[class*="eight wide"].column,
.pq.grid>[class*="eight wide"].column,
.pq.column.grid>[class*="eight wide"].column {
    width: 50% !important;
}

.pq.grid>.row>[class*="nine wide"].column,
.pq.grid>.column.row>[class*="nine wide"].column,
.pq.grid>[class*="nine wide"].column,
.pq.column.grid>[class*="nine wide"].column {
    width: 56.25% !important;
}

.pq.grid>.row>[class*="ten wide"].column,
.pq.grid>.column.row>[class*="ten wide"].column,
.pq.grid>[class*="ten wide"].column,
.pq.column.grid>[class*="ten wide"].column {
    width: 62.5% !important;
}

.pq.grid>.row>[class*="eleven wide"].column,
.pq.grid>.column.row>[class*="eleven wide"].column,
.pq.grid>[class*="eleven wide"].column,
.pq.column.grid>[class*="eleven wide"].column {
    width: 68.75% !important;
}

.pq.grid>.row>[class*="twelve wide"].column,
.pq.grid>.column.row>[class*="twelve wide"].column,
.pq.grid>[class*="twelve wide"].column,
.pq.column.grid>[class*="twelve wide"].column {
    width: 75% !important;
}

.pq.grid>.row>[class*="thirteen wide"].column,
.pq.grid>.column.row>[class*="thirteen wide"].column,
.pq.grid>[class*="thirteen wide"].column,
.pq.column.grid>[class*="thirteen wide"].column {
    width: 81.25% !important;
}

.pq.grid>.row>[class*="fourteen wide"].column,
.pq.grid>.column.row>[class*="fourteen wide"].column,
.pq.grid>[class*="fourteen wide"].column,
.pq.column.grid>[class*="fourteen wide"].column {
    width: 87.5% !important;
}

.pq.grid>.row>[class*="fifteen wide"].column,
.pq.grid>.column.row>[class*="fifteen wide"].column,
.pq.grid>[class*="fifteen wide"].column,
.pq.column.grid>[class*="fifteen wide"].column {
    width: 93.75% !important;
}

.pq.grid>.row>[class*="sixteen wide"].column,
.pq.grid>.column.row>[class*="sixteen wide"].column,
.pq.grid>[class*="sixteen wide"].column,
.pq.column.grid>[class*="sixteen wide"].column {
    width: 100% !important;
}


/*----------------------
    Width per Device
-----------------------*/


/* Mobile Sizing Combinations */

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .pq.grid>.row>[class*="one wide mobile"].column,
    .pq.grid>.column.row>[class*="one wide mobile"].column,
    .pq.grid>[class*="one wide mobile"].column,
    .pq.column.grid>[class*="one wide mobile"].column {
        width: 6.25% !important;
    }
    .pq.grid>.row>[class*="two wide mobile"].column,
    .pq.grid>.column.row>[class*="two wide mobile"].column,
    .pq.grid>[class*="two wide mobile"].column,
    .pq.column.grid>[class*="two wide mobile"].column {
        width: 12.5% !important;
    }
    .pq.grid>.row>[class*="three wide mobile"].column,
    .pq.grid>.column.row>[class*="three wide mobile"].column,
    .pq.grid>[class*="three wide mobile"].column,
    .pq.column.grid>[class*="three wide mobile"].column {
        width: 18.75% !important;
    }
    .pq.grid>.row>[class*="four wide mobile"].column,
    .pq.grid>.column.row>[class*="four wide mobile"].column,
    .pq.grid>[class*="four wide mobile"].column,
    .pq.column.grid>[class*="four wide mobile"].column {
        width: 25% !important;
    }
    .pq.grid>.row>[class*="five wide mobile"].column,
    .pq.grid>.column.row>[class*="five wide mobile"].column,
    .pq.grid>[class*="five wide mobile"].column,
    .pq.column.grid>[class*="five wide mobile"].column {
        width: 31.25% !important;
    }
    .pq.grid>.row>[class*="six wide mobile"].column,
    .pq.grid>.column.row>[class*="six wide mobile"].column,
    .pq.grid>[class*="six wide mobile"].column,
    .pq.column.grid>[class*="six wide mobile"].column {
        width: 37.5% !important;
    }
    .pq.grid>.row>[class*="seven wide mobile"].column,
    .pq.grid>.column.row>[class*="seven wide mobile"].column,
    .pq.grid>[class*="seven wide mobile"].column,
    .pq.column.grid>[class*="seven wide mobile"].column {
        width: 43.75% !important;
    }
    .pq.grid>.row>[class*="eight wide mobile"].column,
    .pq.grid>.column.row>[class*="eight wide mobile"].column,
    .pq.grid>[class*="eight wide mobile"].column,
    .pq.column.grid>[class*="eight wide mobile"].column {
        width: 50% !important;
    }
    .pq.grid>.row>[class*="nine wide mobile"].column,
    .pq.grid>.column.row>[class*="nine wide mobile"].column,
    .pq.grid>[class*="nine wide mobile"].column,
    .pq.column.grid>[class*="nine wide mobile"].column {
        width: 56.25% !important;
    }
    .pq.grid>.row>[class*="ten wide mobile"].column,
    .pq.grid>.column.row>[class*="ten wide mobile"].column,
    .pq.grid>[class*="ten wide mobile"].column,
    .pq.column.grid>[class*="ten wide mobile"].column {
        width: 62.5% !important;
    }
    .pq.grid>.row>[class*="eleven wide mobile"].column,
    .pq.grid>.column.row>[class*="eleven wide mobile"].column,
    .pq.grid>[class*="eleven wide mobile"].column,
    .pq.column.grid>[class*="eleven wide mobile"].column {
        width: 68.75% !important;
    }
    .pq.grid>.row>[class*="twelve wide mobile"].column,
    .pq.grid>.column.row>[class*="twelve wide mobile"].column,
    .pq.grid>[class*="twelve wide mobile"].column,
    .pq.column.grid>[class*="twelve wide mobile"].column {
        width: 75% !important;
    }
    .pq.grid>.row>[class*="thirteen wide mobile"].column,
    .pq.grid>.column.row>[class*="thirteen wide mobile"].column,
    .pq.grid>[class*="thirteen wide mobile"].column,
    .pq.column.grid>[class*="thirteen wide mobile"].column {
        width: 81.25% !important;
    }
    .pq.grid>.row>[class*="fourteen wide mobile"].column,
    .pq.grid>.column.row>[class*="fourteen wide mobile"].column,
    .pq.grid>[class*="fourteen wide mobile"].column,
    .pq.column.grid>[class*="fourteen wide mobile"].column {
        width: 87.5% !important;
    }
    .pq.grid>.row>[class*="fifteen wide mobile"].column,
    .pq.grid>.column.row>[class*="fifteen wide mobile"].column,
    .pq.grid>[class*="fifteen wide mobile"].column,
    .pq.column.grid>[class*="fifteen wide mobile"].column {
        width: 93.75% !important;
    }
    .pq.grid>.row>[class*="sixteen wide mobile"].column,
    .pq.grid>.column.row>[class*="sixteen wide mobile"].column,
    .pq.grid>[class*="sixteen wide mobile"].column,
    .pq.column.grid>[class*="sixteen wide mobile"].column {
        width: 100% !important;
    }
}


/* Tablet Sizing Combinations */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pq.grid>.row>[class*="one wide tablet"].column,
    .pq.grid>.column.row>[class*="one wide tablet"].column,
    .pq.grid>[class*="one wide tablet"].column,
    .pq.column.grid>[class*="one wide tablet"].column {
        width: 6.25% !important;
    }
    .pq.grid>.row>[class*="two wide tablet"].column,
    .pq.grid>.column.row>[class*="two wide tablet"].column,
    .pq.grid>[class*="two wide tablet"].column,
    .pq.column.grid>[class*="two wide tablet"].column {
        width: 12.5% !important;
    }
    .pq.grid>.row>[class*="three wide tablet"].column,
    .pq.grid>.column.row>[class*="three wide tablet"].column,
    .pq.grid>[class*="three wide tablet"].column,
    .pq.column.grid>[class*="three wide tablet"].column {
        width: 18.75% !important;
    }
    .pq.grid>.row>[class*="four wide tablet"].column,
    .pq.grid>.column.row>[class*="four wide tablet"].column,
    .pq.grid>[class*="four wide tablet"].column,
    .pq.column.grid>[class*="four wide tablet"].column {
        width: 25% !important;
    }
    .pq.grid>.row>[class*="five wide tablet"].column,
    .pq.grid>.column.row>[class*="five wide tablet"].column,
    .pq.grid>[class*="five wide tablet"].column,
    .pq.column.grid>[class*="five wide tablet"].column {
        width: 31.25% !important;
    }
    .pq.grid>.row>[class*="six wide tablet"].column,
    .pq.grid>.column.row>[class*="six wide tablet"].column,
    .pq.grid>[class*="six wide tablet"].column,
    .pq.column.grid>[class*="six wide tablet"].column {
        width: 37.5% !important;
    }
    .pq.grid>.row>[class*="seven wide tablet"].column,
    .pq.grid>.column.row>[class*="seven wide tablet"].column,
    .pq.grid>[class*="seven wide tablet"].column,
    .pq.column.grid>[class*="seven wide tablet"].column {
        width: 43.75% !important;
    }
    .pq.grid>.row>[class*="eight wide tablet"].column,
    .pq.grid>.column.row>[class*="eight wide tablet"].column,
    .pq.grid>[class*="eight wide tablet"].column,
    .pq.column.grid>[class*="eight wide tablet"].column {
        width: 50% !important;
    }
    .pq.grid>.row>[class*="nine wide tablet"].column,
    .pq.grid>.column.row>[class*="nine wide tablet"].column,
    .pq.grid>[class*="nine wide tablet"].column,
    .pq.column.grid>[class*="nine wide tablet"].column {
        width: 56.25% !important;
    }
    .pq.grid>.row>[class*="ten wide tablet"].column,
    .pq.grid>.column.row>[class*="ten wide tablet"].column,
    .pq.grid>[class*="ten wide tablet"].column,
    .pq.column.grid>[class*="ten wide tablet"].column {
        width: 62.5% !important;
    }
    .pq.grid>.row>[class*="eleven wide tablet"].column,
    .pq.grid>.column.row>[class*="eleven wide tablet"].column,
    .pq.grid>[class*="eleven wide tablet"].column,
    .pq.column.grid>[class*="eleven wide tablet"].column {
        width: 68.75% !important;
    }
    .pq.grid>.row>[class*="twelve wide tablet"].column,
    .pq.grid>.column.row>[class*="twelve wide tablet"].column,
    .pq.grid>[class*="twelve wide tablet"].column,
    .pq.column.grid>[class*="twelve wide tablet"].column {
        width: 75% !important;
    }
    .pq.grid>.row>[class*="thirteen wide tablet"].column,
    .pq.grid>.column.row>[class*="thirteen wide tablet"].column,
    .pq.grid>[class*="thirteen wide tablet"].column,
    .pq.column.grid>[class*="thirteen wide tablet"].column {
        width: 81.25% !important;
    }
    .pq.grid>.row>[class*="fourteen wide tablet"].column,
    .pq.grid>.column.row>[class*="fourteen wide tablet"].column,
    .pq.grid>[class*="fourteen wide tablet"].column,
    .pq.column.grid>[class*="fourteen wide tablet"].column {
        width: 87.5% !important;
    }
    .pq.grid>.row>[class*="fifteen wide tablet"].column,
    .pq.grid>.column.row>[class*="fifteen wide tablet"].column,
    .pq.grid>[class*="fifteen wide tablet"].column,
    .pq.column.grid>[class*="fifteen wide tablet"].column {
        width: 93.75% !important;
    }
    .pq.grid>.row>[class*="sixteen wide tablet"].column,
    .pq.grid>.column.row>[class*="sixteen wide tablet"].column,
    .pq.grid>[class*="sixteen wide tablet"].column,
    .pq.column.grid>[class*="sixteen wide tablet"].column {
        width: 100% !important;
    }
}


/* Computer/Desktop Sizing Combinations */

@media only screen and (min-width: 992px) {
    .pq.grid>.row>[class*="one wide computer"].column,
    .pq.grid>.column.row>[class*="one wide computer"].column,
    .pq.grid>[class*="one wide computer"].column,
    .pq.column.grid>[class*="one wide computer"].column {
        width: 6.25% !important;
    }
    .pq.grid>.row>[class*="two wide computer"].column,
    .pq.grid>.column.row>[class*="two wide computer"].column,
    .pq.grid>[class*="two wide computer"].column,
    .pq.column.grid>[class*="two wide computer"].column {
        width: 12.5% !important;
    }
    .pq.grid>.row>[class*="three wide computer"].column,
    .pq.grid>.column.row>[class*="three wide computer"].column,
    .pq.grid>[class*="three wide computer"].column,
    .pq.column.grid>[class*="three wide computer"].column {
        width: 18.75% !important;
    }
    .pq.grid>.row>[class*="four wide computer"].column,
    .pq.grid>.column.row>[class*="four wide computer"].column,
    .pq.grid>[class*="four wide computer"].column,
    .pq.column.grid>[class*="four wide computer"].column {
        width: 25% !important;
    }
    .pq.grid>.row>[class*="five wide computer"].column,
    .pq.grid>.column.row>[class*="five wide computer"].column,
    .pq.grid>[class*="five wide computer"].column,
    .pq.column.grid>[class*="five wide computer"].column {
        width: 31.25% !important;
    }
    .pq.grid>.row>[class*="six wide computer"].column,
    .pq.grid>.column.row>[class*="six wide computer"].column,
    .pq.grid>[class*="six wide computer"].column,
    .pq.column.grid>[class*="six wide computer"].column {
        width: 37.5% !important;
    }
    .pq.grid>.row>[class*="seven wide computer"].column,
    .pq.grid>.column.row>[class*="seven wide computer"].column,
    .pq.grid>[class*="seven wide computer"].column,
    .pq.column.grid>[class*="seven wide computer"].column {
        width: 43.75% !important;
    }
    .pq.grid>.row>[class*="eight wide computer"].column,
    .pq.grid>.column.row>[class*="eight wide computer"].column,
    .pq.grid>[class*="eight wide computer"].column,
    .pq.column.grid>[class*="eight wide computer"].column {
        width: 50% !important;
    }
    .pq.grid>.row>[class*="nine wide computer"].column,
    .pq.grid>.column.row>[class*="nine wide computer"].column,
    .pq.grid>[class*="nine wide computer"].column,
    .pq.column.grid>[class*="nine wide computer"].column {
        width: 56.25% !important;
    }
    .pq.grid>.row>[class*="ten wide computer"].column,
    .pq.grid>.column.row>[class*="ten wide computer"].column,
    .pq.grid>[class*="ten wide computer"].column,
    .pq.column.grid>[class*="ten wide computer"].column {
        width: 62.5% !important;
    }
    .pq.grid>.row>[class*="eleven wide computer"].column,
    .pq.grid>.column.row>[class*="eleven wide computer"].column,
    .pq.grid>[class*="eleven wide computer"].column,
    .pq.column.grid>[class*="eleven wide computer"].column {
        width: 68.75% !important;
    }
    .pq.grid>.row>[class*="twelve wide computer"].column,
    .pq.grid>.column.row>[class*="twelve wide computer"].column,
    .pq.grid>[class*="twelve wide computer"].column,
    .pq.column.grid>[class*="twelve wide computer"].column {
        width: 75% !important;
    }
    .pq.grid>.row>[class*="thirteen wide computer"].column,
    .pq.grid>.column.row>[class*="thirteen wide computer"].column,
    .pq.grid>[class*="thirteen wide computer"].column,
    .pq.column.grid>[class*="thirteen wide computer"].column {
        width: 81.25% !important;
    }
    .pq.grid>.row>[class*="fourteen wide computer"].column,
    .pq.grid>.column.row>[class*="fourteen wide computer"].column,
    .pq.grid>[class*="fourteen wide computer"].column,
    .pq.column.grid>[class*="fourteen wide computer"].column {
        width: 87.5% !important;
    }
    .pq.grid>.row>[class*="fifteen wide computer"].column,
    .pq.grid>.column.row>[class*="fifteen wide computer"].column,
    .pq.grid>[class*="fifteen wide computer"].column,
    .pq.column.grid>[class*="fifteen wide computer"].column {
        width: 93.75% !important;
    }
    .pq.grid>.row>[class*="sixteen wide computer"].column,
    .pq.grid>.column.row>[class*="sixteen wide computer"].column,
    .pq.grid>[class*="sixteen wide computer"].column,
    .pq.column.grid>[class*="sixteen wide computer"].column {
        width: 100% !important;
    }
}


/* Large Monitor Sizing Combinations */

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
    .pq.grid>.row>[class*="one wide large screen"].column,
    .pq.grid>.column.row>[class*="one wide large screen"].column,
    .pq.grid>[class*="one wide large screen"].column,
    .pq.column.grid>[class*="one wide large screen"].column {
        width: 6.25% !important;
    }
    .pq.grid>.row>[class*="two wide large screen"].column,
    .pq.grid>.column.row>[class*="two wide large screen"].column,
    .pq.grid>[class*="two wide large screen"].column,
    .pq.column.grid>[class*="two wide large screen"].column {
        width: 12.5% !important;
    }
    .pq.grid>.row>[class*="three wide large screen"].column,
    .pq.grid>.column.row>[class*="three wide large screen"].column,
    .pq.grid>[class*="three wide large screen"].column,
    .pq.column.grid>[class*="three wide large screen"].column {
        width: 18.75% !important;
    }
    .pq.grid>.row>[class*="four wide large screen"].column,
    .pq.grid>.column.row>[class*="four wide large screen"].column,
    .pq.grid>[class*="four wide large screen"].column,
    .pq.column.grid>[class*="four wide large screen"].column {
        width: 25% !important;
    }
    .pq.grid>.row>[class*="five wide large screen"].column,
    .pq.grid>.column.row>[class*="five wide large screen"].column,
    .pq.grid>[class*="five wide large screen"].column,
    .pq.column.grid>[class*="five wide large screen"].column {
        width: 31.25% !important;
    }
    .pq.grid>.row>[class*="six wide large screen"].column,
    .pq.grid>.column.row>[class*="six wide large screen"].column,
    .pq.grid>[class*="six wide large screen"].column,
    .pq.column.grid>[class*="six wide large screen"].column {
        width: 37.5% !important;
    }
    .pq.grid>.row>[class*="seven wide large screen"].column,
    .pq.grid>.column.row>[class*="seven wide large screen"].column,
    .pq.grid>[class*="seven wide large screen"].column,
    .pq.column.grid>[class*="seven wide large screen"].column {
        width: 43.75% !important;
    }
    .pq.grid>.row>[class*="eight wide large screen"].column,
    .pq.grid>.column.row>[class*="eight wide large screen"].column,
    .pq.grid>[class*="eight wide large screen"].column,
    .pq.column.grid>[class*="eight wide large screen"].column {
        width: 50% !important;
    }
    .pq.grid>.row>[class*="nine wide large screen"].column,
    .pq.grid>.column.row>[class*="nine wide large screen"].column,
    .pq.grid>[class*="nine wide large screen"].column,
    .pq.column.grid>[class*="nine wide large screen"].column {
        width: 56.25% !important;
    }
    .pq.grid>.row>[class*="ten wide large screen"].column,
    .pq.grid>.column.row>[class*="ten wide large screen"].column,
    .pq.grid>[class*="ten wide large screen"].column,
    .pq.column.grid>[class*="ten wide large screen"].column {
        width: 62.5% !important;
    }
    .pq.grid>.row>[class*="eleven wide large screen"].column,
    .pq.grid>.column.row>[class*="eleven wide large screen"].column,
    .pq.grid>[class*="eleven wide large screen"].column,
    .pq.column.grid>[class*="eleven wide large screen"].column {
        width: 68.75% !important;
    }
    .pq.grid>.row>[class*="twelve wide large screen"].column,
    .pq.grid>.column.row>[class*="twelve wide large screen"].column,
    .pq.grid>[class*="twelve wide large screen"].column,
    .pq.column.grid>[class*="twelve wide large screen"].column {
        width: 75% !important;
    }
    .pq.grid>.row>[class*="thirteen wide large screen"].column,
    .pq.grid>.column.row>[class*="thirteen wide large screen"].column,
    .pq.grid>[class*="thirteen wide large screen"].column,
    .pq.column.grid>[class*="thirteen wide large screen"].column {
        width: 81.25% !important;
    }
    .pq.grid>.row>[class*="fourteen wide large screen"].column,
    .pq.grid>.column.row>[class*="fourteen wide large screen"].column,
    .pq.grid>[class*="fourteen wide large screen"].column,
    .pq.column.grid>[class*="fourteen wide large screen"].column {
        width: 87.5% !important;
    }
    .pq.grid>.row>[class*="fifteen wide large screen"].column,
    .pq.grid>.column.row>[class*="fifteen wide large screen"].column,
    .pq.grid>[class*="fifteen wide large screen"].column,
    .pq.column.grid>[class*="fifteen wide large screen"].column {
        width: 93.75% !important;
    }
    .pq.grid>.row>[class*="sixteen wide large screen"].column,
    .pq.grid>.column.row>[class*="sixteen wide large screen"].column,
    .pq.grid>[class*="sixteen wide large screen"].column,
    .pq.column.grid>[class*="sixteen wide large screen"].column {
        width: 100% !important;
    }
}


/* Widescreen Sizing Combinations */

@media only screen and (min-width: 1920px) {
    .pq.grid>.row>[class*="one wide widescreen"].column,
    .pq.grid>.column.row>[class*="one wide widescreen"].column,
    .pq.grid>[class*="one wide widescreen"].column,
    .pq.column.grid>[class*="one wide widescreen"].column {
        width: 6.25% !important;
    }
    .pq.grid>.row>[class*="two wide widescreen"].column,
    .pq.grid>.column.row>[class*="two wide widescreen"].column,
    .pq.grid>[class*="two wide widescreen"].column,
    .pq.column.grid>[class*="two wide widescreen"].column {
        width: 12.5% !important;
    }
    .pq.grid>.row>[class*="three wide widescreen"].column,
    .pq.grid>.column.row>[class*="three wide widescreen"].column,
    .pq.grid>[class*="three wide widescreen"].column,
    .pq.column.grid>[class*="three wide widescreen"].column {
        width: 18.75% !important;
    }
    .pq.grid>.row>[class*="four wide widescreen"].column,
    .pq.grid>.column.row>[class*="four wide widescreen"].column,
    .pq.grid>[class*="four wide widescreen"].column,
    .pq.column.grid>[class*="four wide widescreen"].column {
        width: 25% !important;
    }
    .pq.grid>.row>[class*="five wide widescreen"].column,
    .pq.grid>.column.row>[class*="five wide widescreen"].column,
    .pq.grid>[class*="five wide widescreen"].column,
    .pq.column.grid>[class*="five wide widescreen"].column {
        width: 31.25% !important;
    }
    .pq.grid>.row>[class*="six wide widescreen"].column,
    .pq.grid>.column.row>[class*="six wide widescreen"].column,
    .pq.grid>[class*="six wide widescreen"].column,
    .pq.column.grid>[class*="six wide widescreen"].column {
        width: 37.5% !important;
    }
    .pq.grid>.row>[class*="seven wide widescreen"].column,
    .pq.grid>.column.row>[class*="seven wide widescreen"].column,
    .pq.grid>[class*="seven wide widescreen"].column,
    .pq.column.grid>[class*="seven wide widescreen"].column {
        width: 43.75% !important;
    }
    .pq.grid>.row>[class*="eight wide widescreen"].column,
    .pq.grid>.column.row>[class*="eight wide widescreen"].column,
    .pq.grid>[class*="eight wide widescreen"].column,
    .pq.column.grid>[class*="eight wide widescreen"].column {
        width: 50% !important;
    }
    .pq.grid>.row>[class*="nine wide widescreen"].column,
    .pq.grid>.column.row>[class*="nine wide widescreen"].column,
    .pq.grid>[class*="nine wide widescreen"].column,
    .pq.column.grid>[class*="nine wide widescreen"].column {
        width: 56.25% !important;
    }
    .pq.grid>.row>[class*="ten wide widescreen"].column,
    .pq.grid>.column.row>[class*="ten wide widescreen"].column,
    .pq.grid>[class*="ten wide widescreen"].column,
    .pq.column.grid>[class*="ten wide widescreen"].column {
        width: 62.5% !important;
    }
    .pq.grid>.row>[class*="eleven wide widescreen"].column,
    .pq.grid>.column.row>[class*="eleven wide widescreen"].column,
    .pq.grid>[class*="eleven wide widescreen"].column,
    .pq.column.grid>[class*="eleven wide widescreen"].column {
        width: 68.75% !important;
    }
    .pq.grid>.row>[class*="twelve wide widescreen"].column,
    .pq.grid>.column.row>[class*="twelve wide widescreen"].column,
    .pq.grid>[class*="twelve wide widescreen"].column,
    .pq.column.grid>[class*="twelve wide widescreen"].column {
        width: 75% !important;
    }
    .pq.grid>.row>[class*="thirteen wide widescreen"].column,
    .pq.grid>.column.row>[class*="thirteen wide widescreen"].column,
    .pq.grid>[class*="thirteen wide widescreen"].column,
    .pq.column.grid>[class*="thirteen wide widescreen"].column {
        width: 81.25% !important;
    }
    .pq.grid>.row>[class*="fourteen wide widescreen"].column,
    .pq.grid>.column.row>[class*="fourteen wide widescreen"].column,
    .pq.grid>[class*="fourteen wide widescreen"].column,
    .pq.column.grid>[class*="fourteen wide widescreen"].column {
        width: 87.5% !important;
    }
    .pq.grid>.row>[class*="fifteen wide widescreen"].column,
    .pq.grid>.column.row>[class*="fifteen wide widescreen"].column,
    .pq.grid>[class*="fifteen wide widescreen"].column,
    .pq.column.grid>[class*="fifteen wide widescreen"].column {
        width: 93.75% !important;
    }
    .pq.grid>.row>[class*="sixteen wide widescreen"].column,
    .pq.grid>.column.row>[class*="sixteen wide widescreen"].column,
    .pq.grid>[class*="sixteen wide widescreen"].column,
    .pq.column.grid>[class*="sixteen wide widescreen"].column {
        width: 100% !important;
    }
}


/*----------------------
        Centered
-----------------------*/

.pq.centered.grid,
.pq.centered.grid>.row,
.pq.grid>.centered.row {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.pq.centered.grid>.column:not(.aligned):not(.justified):not(.row),
.pq.centered.grid>.row>.column:not(.aligned):not(.justified),
.pq.grid .centered.row>.column:not(.aligned):not(.justified) {
    text-align: left;
}

.pq.grid>.centered.column,
.pq.grid>.row>.centered.column {
    display: block;
    margin-left: auto;
    margin-right: auto;
}


/*----------------------
        Relaxed
-----------------------*/

.pq.relaxed.grid>.column:not(.row),
.pq.relaxed.grid>.row>.column,
.pq.grid>.relaxed.row>.column {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.pq[class*="very relaxed"].grid>.column:not(.row),
.pq[class*="very relaxed"].grid>.row>.column,
.pq.grid>[class*="very relaxed"].row>.column {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}


/* Coupling with UI Divider */

.pq.relaxed.grid .row+.pq.divider,
.pq.grid .relaxed.row+.pq.divider {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.pq[class*="very relaxed"].grid .row+.pq.divider,
.pq.grid [class*="very relaxed"].row+.pq.divider {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
}


/*----------------------
        Padded
-----------------------*/

.pq.padded.grid:not(.vertically):not(.horizontally) {
    margin: 0em !important;
}

[class*="horizontally padded"].pq.grid {
    margin-left: 0em !important;
    margin-right: 0em !important;
}

[class*="vertically padded"].pq.grid {
    margin-top: 0em !important;
    margin-bottom: 0em !important;
}


/*----------------------
       "Floated"
-----------------------*/

.pq.grid [class*="left floated"].column {
    margin-right: auto;
}

.pq.grid [class*="right floated"].column {
    margin-left: auto;
}


/*----------------------
        Divided
-----------------------*/

.pq.divided.grid:not([class*="vertically divided"])>.column:not(.row),
.pq.divided.grid:not([class*="vertically divided"])>.row>.column {
    -webkit-box-shadow: -1px 0px 0px 0px rgba(34, 36, 38, 0.15);
    box-shadow: -1px 0px 0px 0px rgba(34, 36, 38, 0.15);
}


/* Swap from padding to margin on columns to have dividers align */

.pq[class*="vertically divided"].grid>.column:not(.row),
.pq[class*="vertically divided"].grid>.row>.column {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.pq[class*="vertically divided"].grid>.row {
    margin-top: 0em;
    margin-bottom: 0em;
}


/* No divider on first column on row */

.pq.divided.grid:not([class*="vertically divided"])>.column:first-child,
.pq.divided.grid:not([class*="vertically divided"])>.row>.column:first-child {
    -webkit-box-shadow: none;
    box-shadow: none;
}


/* No space on top of first row */

.pq[class*="vertically divided"].grid>.row:first-child>.column {
    margin-top: 0em;
}


/* Divided Row */

.pq.grid>.divided.row>.column {
    -webkit-box-shadow: -1px 0px 0px 0px rgba(34, 36, 38, 0.15);
    box-shadow: -1px 0px 0px 0px rgba(34, 36, 38, 0.15);
}

.pq.grid>.divided.row>.column:first-child {
    -webkit-box-shadow: none;
    box-shadow: none;
}


/* Vertically Divided */

.pq[class*="vertically divided"].grid>.row {
    position: relative;
}

.pq[class*="vertically divided"].grid>.row:before {
    position: absolute;
    content: "";
    top: 0em;
    left: 0px;
    width: calc(100% - 2rem);
    height: 1px;
    margin: 0% 1rem;
    -webkit-box-shadow: 0px -1px 0px 0px rgba(34, 36, 38, 0.15);
    box-shadow: 0px -1px 0px 0px rgba(34, 36, 38, 0.15);
}


/* Padded Horizontally Divided */

[class*="horizontally padded"].pq.divided.grid,
.pq.padded.divided.grid:not(.vertically):not(.horizontally) {
    width: 100%;
}


/* First Row Vertically Divided */

.pq[class*="vertically divided"].grid>.row:first-child:before {
    -webkit-box-shadow: none;
    box-shadow: none;
}


/* Inverted Divided */

.pq.inverted.divided.grid:not([class*="vertically divided"])>.column:not(.row),
.pq.inverted.divided.grid:not([class*="vertically divided"])>.row>.column {
    -webkit-box-shadow: -1px 0px 0px 0px rgba(255, 255, 255, 0.1);
    box-shadow: -1px 0px 0px 0px rgba(255, 255, 255, 0.1);
}

.pq.inverted.divided.grid:not([class*="vertically divided"])>.column:not(.row):first-child,
.pq.inverted.divided.grid:not([class*="vertically divided"])>.row>.column:first-child {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pq.inverted[class*="vertically divided"].grid>.row:before {
    -webkit-box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.1);
    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.1);
}


/* Relaxed */

.pq.relaxed[class*="vertically divided"].grid>.row:before {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    width: calc(100% - 3rem);
}

.pq[class*="very relaxed"][class*="vertically divided"].grid>.row:before {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    width: calc(100% - 5rem);
}


/*----------------------
         Celled
-----------------------*/

.pq.celled.grid {
    width: 100%;
    margin: 1em 0em;
    -webkit-box-shadow: 0px 0px 0px 1px #D4D4D5;
    box-shadow: 0px 0px 0px 1px #D4D4D5;
}

.pq.celled.grid>.row {
    width: 100% !important;
    margin: 0em;
    padding: 0em;
    -webkit-box-shadow: 0px -1px 0px 0px #D4D4D5;
    box-shadow: 0px -1px 0px 0px #D4D4D5;
}

.pq.celled.grid>.column:not(.row),
.pq.celled.grid>.row>.column {
    -webkit-box-shadow: -1px 0px 0px 0px #D4D4D5;
    box-shadow: -1px 0px 0px 0px #D4D4D5;
}

.pq.celled.grid>.column:first-child,
.pq.celled.grid>.row>.column:first-child {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pq.celled.grid>.column:not(.row),
.pq.celled.grid>.row>.column {
    padding: 1em;
}

.pq.relaxed.celled.grid>.column:not(.row),
.pq.relaxed.celled.grid>.row>.column {
    padding: 1.5em;
}

.pq[class*="very relaxed"].celled.grid>.column:not(.row),
.pq[class*="very relaxed"].celled.grid>.row>.column {
    padding: 2em;
}


/* Internally Celled */

.pq[class*="internally celled"].grid {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0em;
}

.pq[class*="internally celled"].grid>.row:first-child {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pq[class*="internally celled"].grid>.row>.column:first-child {
    -webkit-box-shadow: none;
    box-shadow: none;
}


/*----------------------
   Vertically Aligned
-----------------------*/


/* Top Aligned */

.pq[class*="top aligned"].grid>.column:not(.row),
.pq[class*="top aligned"].grid>.row>.column,
.pq.grid>[class*="top aligned"].row>.column,
.pq.grid>[class*="top aligned"].column:not(.row),
.pq.grid>.row>[class*="top aligned"].column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    vertical-align: top;
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
}


/* Middle Aligned */

.pq[class*="middle aligned"].grid>.column:not(.row),
.pq[class*="middle aligned"].grid>.row>.column,
.pq.grid>[class*="middle aligned"].row>.column,
.pq.grid>[class*="middle aligned"].column:not(.row),
.pq.grid>.row>[class*="middle aligned"].column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    vertical-align: middle;
    -ms-flex-item-align: center !important;
    align-self: center !important;
}


/* Bottom Aligned */

.pq[class*="bottom aligned"].grid>.column:not(.row),
.pq[class*="bottom aligned"].grid>.row>.column,
.pq.grid>[class*="bottom aligned"].row>.column,
.pq.grid>[class*="bottom aligned"].column:not(.row),
.pq.grid>.row>[class*="bottom aligned"].column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    vertical-align: bottom;
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
}


/* Stretched */

.pq.stretched.grid>.row>.column,
.pq.stretched.grid>.column,
.pq.grid>.stretched.row>.column,
.pq.grid>.stretched.column:not(.row),
.pq.grid>.row>.stretched.column {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.pq.stretched.grid>.row>.column>*,
.pq.stretched.grid>.column>*,
.pq.grid>.stretched.row>.column>*,
.pq.grid>.stretched.column:not(.row)>*,
.pq.grid>.row>.stretched.column>* {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}


/*----------------------
  Horizontally Centered
-----------------------*/


/* Left Aligned */

.pq[class*="left aligned"].grid>.column,
.pq[class*="left aligned"].grid>.row>.column,
.pq.grid>[class*="left aligned"].row>.column,
.pq.grid>[class*="left aligned"].column.column,
.pq.grid>.row>[class*="left aligned"].column.column {
    text-align: left;
    -ms-flex-item-align: inherit;
    align-self: inherit;
}


/* Center Aligned */

.pq[class*="center aligned"].grid>.column,
.pq[class*="center aligned"].grid>.row>.column,
.pq.grid>[class*="center aligned"].row>.column,
.pq.grid>[class*="center aligned"].column.column,
.pq.grid>.row>[class*="center aligned"].column.column {
    text-align: center;
    -ms-flex-item-align: inherit;
    align-self: inherit;
}

.pq[class*="center aligned"].grid {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}


/* Right Aligned */

.pq[class*="right aligned"].grid>.column,
.pq[class*="right aligned"].grid>.row>.column,
.pq.grid>[class*="right aligned"].row>.column,
.pq.grid>[class*="right aligned"].column.column,
.pq.grid>.row>[class*="right aligned"].column.column {
    text-align: right;
    -ms-flex-item-align: inherit;
    align-self: inherit;
}


/* Justified */

.pq.justified.grid>.column,
.pq.justified.grid>.row>.column,
.pq.grid>.justified.row>.column,
.pq.grid>.justified.column.column,
.pq.grid>.row>.justified.column.column {
    text-align: justify;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}


/*----------------------
         Colored
-----------------------*/

.pq.grid>.row>.red.column,
.pq.grid>.row>.orange.column,
.pq.grid>.row>.yellow.column,
.pq.grid>.row>.olive.column,
.pq.grid>.row>.green.column,
.pq.grid>.row>.teal.column,
.pq.grid>.row>.blue.column,
.pq.grid>.row>.violet.column,
.pq.grid>.row>.purple.column,
.pq.grid>.row>.pink.column,
.pq.grid>.row>.brown.column,
.pq.grid>.row>.grey.column,
.pq.grid>.row>.black.column {
    margin-top: -1rem;
    margin-bottom: -1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}


/* Red */

.pq.grid>.red.row,
.pq.grid>.red.column,
.pq.grid>.row>.red.column {
    background-color: #DB2828 !important;
    color: #FFFFFF;
}


/* Orange */

.pq.grid>.orange.row,
.pq.grid>.orange.column,
.pq.grid>.row>.orange.column {
    background-color: #F2711C !important;
    color: #FFFFFF;
}


/* Yellow */

.pq.grid>.yellow.row,
.pq.grid>.yellow.column,
.pq.grid>.row>.yellow.column {
    background-color: #FBBD08 !important;
    color: #FFFFFF;
}


/* Olive */

.pq.grid>.olive.row,
.pq.grid>.olive.column,
.pq.grid>.row>.olive.column {
    background-color: #B5CC18 !important;
    color: #FFFFFF;
}


/* Green */

.pq.grid>.green.row,
.pq.grid>.green.column,
.pq.grid>.row>.green.column {
    background-color: #21BA45 !important;
    color: #FFFFFF;
}


/* Teal */

.pq.grid>.teal.row,
.pq.grid>.teal.column,
.pq.grid>.row>.teal.column {
    background-color: #00B5AD !important;
    color: #FFFFFF;
}


/* Blue */

.pq.grid>.blue.row,
.pq.grid>.blue.column,
.pq.grid>.row>.blue.column {
    background-color: #2185D0 !important;
    color: #FFFFFF;
}


/* Violet */

.pq.grid>.violet.row,
.pq.grid>.violet.column,
.pq.grid>.row>.violet.column {
    background-color: #6435C9 !important;
    color: #FFFFFF;
}


/* Purple */

.pq.grid>.purple.row,
.pq.grid>.purple.column,
.pq.grid>.row>.purple.column {
    background-color: #A333C8 !important;
    color: #FFFFFF;
}


/* Pink */

.pq.grid>.pink.row,
.pq.grid>.pink.column,
.pq.grid>.row>.pink.column {
    background-color: #E03997 !important;
    color: #FFFFFF;
}


/* Brown */

.pq.grid>.brown.row,
.pq.grid>.brown.column,
.pq.grid>.row>.brown.column {
    background-color: #A5673F !important;
    color: #FFFFFF;
}


/* Grey */

.pq.grid>.grey.row,
.pq.grid>.grey.column,
.pq.grid>.row>.grey.column {
    background-color: #767676 !important;
    color: #FFFFFF;
}


/* Black */

.pq.grid>.black.row,
.pq.grid>.black.column,
.pq.grid>.row>.black.column {
    background-color: #1B1C1D !important;
    color: #FFFFFF;
}


/*----------------------
      Equal Width
-----------------------*/

.pq[class*="equal width"].grid>.column:not(.row),
.pq[class*="equal width"].grid>.row>.column,
.pq.grid>[class*="equal width"].row>.column {
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.pq[class*="equal width"].grid>.wide.column,
.pq[class*="equal width"].grid>.row>.wide.column,
.pq.grid>[class*="equal width"].row>.wide.column {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
}


/*----------------------
        Reverse
-----------------------*/


/* Mobile */

@media only screen and (max-width: 767px) {
    .pq[class*="mobile reversed"].grid,
    .pq[class*="mobile reversed"].grid>.row,
    .pq.grid>[class*="mobile reversed"].row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
    .pq[class*="mobile vertically reversed"].grid,
    .pq.stackable[class*="mobile reversed"] {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
    /* Divided Reversed */
    .pq[class*="mobile reversed"].divided.grid:not([class*="vertically divided"])>.column:first-child,
    .pq[class*="mobile reversed"].divided.grid:not([class*="vertically divided"])>.row>.column:first-child {
        -webkit-box-shadow: -1px 0px 0px 0px rgba(34, 36, 38, 0.15);
        box-shadow: -1px 0px 0px 0px rgba(34, 36, 38, 0.15);
    }
    .pq[class*="mobile reversed"].divided.grid:not([class*="vertically divided"])>.column:last-child,
    .pq[class*="mobile reversed"].divided.grid:not([class*="vertically divided"])>.row>.column:last-child {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    /* Vertically Divided Reversed */
    .pq.grid[class*="vertically divided"][class*="mobile vertically reversed"]>.row:first-child:before {
        -webkit-box-shadow: 0px -1px 0px 0px rgba(34, 36, 38, 0.15);
        box-shadow: 0px -1px 0px 0px rgba(34, 36, 38, 0.15);
    }
    .pq.grid[class*="vertically divided"][class*="mobile vertically reversed"]>.row:last-child:before {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    /* Celled Reversed */
    .pq[class*="mobile reversed"].celled.grid>.row>.column:first-child {
        -webkit-box-shadow: -1px 0px 0px 0px #D4D4D5;
        box-shadow: -1px 0px 0px 0px #D4D4D5;
    }
    .pq[class*="mobile reversed"].celled.grid>.row>.column:last-child {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}


/* Tablet */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pq[class*="tablet reversed"].grid,
    .pq[class*="tablet reversed"].grid>.row,
    .pq.grid>[class*="tablet reversed"].row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
    .pq[class*="tablet vertically reversed"].grid {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
    /* Divided Reversed */
    .pq[class*="tablet reversed"].divided.grid:not([class*="vertically divided"])>.column:first-child,
    .pq[class*="tablet reversed"].divided.grid:not([class*="vertically divided"])>.row>.column:first-child {
        -webkit-box-shadow: -1px 0px 0px 0px rgba(34, 36, 38, 0.15);
        box-shadow: -1px 0px 0px 0px rgba(34, 36, 38, 0.15);
    }
    .pq[class*="tablet reversed"].divided.grid:not([class*="vertically divided"])>.column:last-child,
    .pq[class*="tablet reversed"].divided.grid:not([class*="vertically divided"])>.row>.column:last-child {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    /* Vertically Divided Reversed */
    .pq.grid[class*="vertically divided"][class*="tablet vertically reversed"]>.row:first-child:before {
        -webkit-box-shadow: 0px -1px 0px 0px rgba(34, 36, 38, 0.15);
        box-shadow: 0px -1px 0px 0px rgba(34, 36, 38, 0.15);
    }
    .pq.grid[class*="vertically divided"][class*="tablet vertically reversed"]>.row:last-child:before {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    /* Celled Reversed */
    .pq[class*="tablet reversed"].celled.grid>.row>.column:first-child {
        -webkit-box-shadow: -1px 0px 0px 0px #D4D4D5;
        box-shadow: -1px 0px 0px 0px #D4D4D5;
    }
    .pq[class*="tablet reversed"].celled.grid>.row>.column:last-child {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}


/* Computer */

@media only screen and (min-width: 992px) {
    .pq[class*="computer reversed"].grid,
    .pq[class*="computer reversed"].grid>.row,
    .pq.grid>[class*="computer reversed"].row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }
    .pq[class*="computer vertically reversed"].grid {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
    /* Divided Reversed */
    .pq[class*="computer reversed"].divided.grid:not([class*="vertically divided"])>.column:first-child,
    .pq[class*="computer reversed"].divided.grid:not([class*="vertically divided"])>.row>.column:first-child {
        -webkit-box-shadow: -1px 0px 0px 0px rgba(34, 36, 38, 0.15);
        box-shadow: -1px 0px 0px 0px rgba(34, 36, 38, 0.15);
    }
    .pq[class*="computer reversed"].divided.grid:not([class*="vertically divided"])>.column:last-child,
    .pq[class*="computer reversed"].divided.grid:not([class*="vertically divided"])>.row>.column:last-child {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    /* Vertically Divided Reversed */
    .pq.grid[class*="vertically divided"][class*="computer vertically reversed"]>.row:first-child:before {
        -webkit-box-shadow: 0px -1px 0px 0px rgba(34, 36, 38, 0.15);
        box-shadow: 0px -1px 0px 0px rgba(34, 36, 38, 0.15);
    }
    .pq.grid[class*="vertically divided"][class*="computer vertically reversed"]>.row:last-child:before {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    /* Celled Reversed */
    .pq[class*="computer reversed"].celled.grid>.row>.column:first-child {
        -webkit-box-shadow: -1px 0px 0px 0px #D4D4D5;
        box-shadow: -1px 0px 0px 0px #D4D4D5;
    }
    .pq[class*="computer reversed"].celled.grid>.row>.column:last-child {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}


/*-------------------
      Doubling
--------------------*/


/* Tablet Only */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pq.doubling.grid {
        width: auto;
    }
    .pq.grid>.doubling.row,
    .pq.doubling.grid>.row {
        margin: 0em !important;
        padding: 0em !important;
    }
    .pq.grid>.doubling.row>.column,
    .pq.doubling.grid>.row>.column {
        display: inline-block !important;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        margin: 0em;
    }
    .pq[class*="two column"].doubling.grid>.row>.column,
    .pq[class*="two column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="two column"].doubling.row.row>.column {
        width: 100% !important;
    }
    .pq[class*="three column"].doubling.grid>.row>.column,
    .pq[class*="three column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="three column"].doubling.row.row>.column {
        width: 50% !important;
    }
    .pq[class*="four column"].doubling.grid>.row>.column,
    .pq[class*="four column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="four column"].doubling.row.row>.column {
        width: 50% !important;
    }
    .pq[class*="five column"].doubling.grid>.row>.column,
    .pq[class*="five column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="five column"].doubling.row.row>.column {
        width: 33.33333333% !important;
    }
    .pq[class*="six column"].doubling.grid>.row>.column,
    .pq[class*="six column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="six column"].doubling.row.row>.column {
        width: 33.33333333% !important;
    }
    .pq[class*="seven column"].doubling.grid>.row>.column,
    .pq[class*="seven column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="seven column"].doubling.row.row>.column {
        width: 33.33333333% !important;
    }
    .pq[class*="eight column"].doubling.grid>.row>.column,
    .pq[class*="eight column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="eight column"].doubling.row.row>.column {
        width: 25% !important;
    }
    .pq[class*="nine column"].doubling.grid>.row>.column,
    .pq[class*="nine column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="nine column"].doubling.row.row>.column {
        width: 25% !important;
    }
    .pq[class*="ten column"].doubling.grid>.row>.column,
    .pq[class*="ten column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="ten column"].doubling.row.row>.column {
        width: 20% !important;
    }
    .pq[class*="eleven column"].doubling.grid>.row>.column,
    .pq[class*="eleven column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="eleven column"].doubling.row.row>.column {
        width: 20% !important;
    }
    .pq[class*="twelve column"].doubling.grid>.row>.column,
    .pq[class*="twelve column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="twelve column"].doubling.row.row>.column {
        width: 16.66666667% !important;
    }
    .pq[class*="thirteen column"].doubling.grid>.row>.column,
    .pq[class*="thirteen column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="thirteen column"].doubling.row.row>.column {
        width: 16.66666667% !important;
    }
    .pq[class*="fourteen column"].doubling.grid>.row>.column,
    .pq[class*="fourteen column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="fourteen column"].doubling.row.row>.column {
        width: 14.28571429% !important;
    }
    .pq[class*="fifteen column"].doubling.grid>.row>.column,
    .pq[class*="fifteen column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="fifteen column"].doubling.row.row>.column {
        width: 14.28571429% !important;
    }
    .pq[class*="sixteen column"].doubling.grid>.row>.column,
    .pq[class*="sixteen column"].doubling.grid>.column:not(.row),
    .pq.grid>[class*="sixteen column"].doubling.row.row>.column {
        width: 12.5% !important;
    }
}


/* Mobile Only */

@media only screen and (max-width: 767px) {
    .pq.grid>.doubling.row,
    .pq.doubling.grid>.row {
        margin: 0em !important;
        padding: 0em !important;
    }
    .pq.grid>.doubling.row>.column,
    .pq.doubling.grid>.row>.column {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        margin: 0em !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    .pq[class*="two column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="two column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="two column"].doubling:not(.stackable).row.row>.column {
        width: 100% !important;
    }
    .pq[class*="three column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="three column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="three column"].doubling:not(.stackable).row.row>.column {
        width: 50% !important;
    }
    .pq[class*="four column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="four column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="four column"].doubling:not(.stackable).row.row>.column {
        width: 50% !important;
    }
    .pq[class*="five column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="five column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="five column"].doubling:not(.stackable).row.row>.column {
        width: 50% !important;
    }
    .pq[class*="six column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="six column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="six column"].doubling:not(.stackable).row.row>.column {
        width: 50% !important;
    }
    .pq[class*="seven column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="seven column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="seven column"].doubling:not(.stackable).row.row>.column {
        width: 50% !important;
    }
    .pq[class*="eight column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="eight column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="eight column"].doubling:not(.stackable).row.row>.column {
        width: 50% !important;
    }
    .pq[class*="nine column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="nine column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="nine column"].doubling:not(.stackable).row.row>.column {
        width: 33.33333333% !important;
    }
    .pq[class*="ten column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="ten column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="ten column"].doubling:not(.stackable).row.row>.column {
        width: 33.33333333% !important;
    }
    .pq[class*="eleven column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="eleven column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="eleven column"].doubling:not(.stackable).row.row>.column {
        width: 33.33333333% !important;
    }
    .pq[class*="twelve column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="twelve column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="twelve column"].doubling:not(.stackable).row.row>.column {
        width: 33.33333333% !important;
    }
    .pq[class*="thirteen column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="thirteen column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="thirteen column"].doubling:not(.stackable).row.row>.column {
        width: 33.33333333% !important;
    }
    .pq[class*="fourteen column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="fourteen column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="fourteen column"].doubling:not(.stackable).row.row>.column {
        width: 25% !important;
    }
    .pq[class*="fifteen column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="fifteen column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="fifteen column"].doubling:not(.stackable).row.row>.column {
        width: 25% !important;
    }
    .pq[class*="sixteen column"].doubling:not(.stackable).grid>.row>.column,
    .pq[class*="sixteen column"].doubling:not(.stackable).grid>.column:not(.row),
    .pq.grid>[class*="sixteen column"].doubling:not(.stackable).row.row>.column {
        width: 25% !important;
    }
}


/*-------------------
      Stackable
--------------------*/

@media only screen and (max-width: 767px) {
    .pq.stackable.grid {
        width: auto;
        margin-left: 0em !important;
        margin-right: 0em !important;
    }
    .pq.stackable.grid>.row>.wide.column,
    .pq.stackable.grid>.wide.column,
    .pq.stackable.grid>.column.grid>.column,
    .pq.stackable.grid>.column.row>.column,
    .pq.stackable.grid>.row>.column,
    .pq.stackable.grid>.column:not(.row),
    .pq.grid>.stackable.stackable.row>.column {
        width: 100% !important;
        margin: 0em 0em !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        padding: 1rem 1rem !important;
    }
    .pq.stackable.grid:not(.vertically)>.row {
        margin: 0em;
        padding: 0em;
    }
    /* Coupling */
    .pq.container>.pq.stackable.grid>.column,
    .pq.container>.pq.stackable.grid>.row>.column {
        padding-left: 0em !important;
        padding-right: 0em !important;
    }
    /* Don't pad inside segment or nested grid */
    .pq.grid .pq.stackable.grid,
    .pq.segment:not(.vertical) .pq.stackable.page.grid {
        margin-left: -1rem !important;
        margin-right: -1rem !important;
    }
    /* Divided Stackable */
    .pq.stackable.divided.grid>.row:first-child>.column:first-child,
    .pq.stackable.celled.grid>.row:first-child>.column:first-child,
    .pq.stackable.divided.grid>.column:not(.row):first-child,
    .pq.stackable.celled.grid>.column:not(.row):first-child {
        border-top: none !important;
    }
    .pq.inverted.stackable.celled.grid>.column:not(.row),
    .pq.inverted.stackable.divided.grid>.column:not(.row),
    .pq.inverted.stackable.celled.grid>.row>.column,
    .pq.inverted.stackable.divided.grid>.row>.column {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
    .pq.stackable.celled.grid>.column:not(.row),
    .pq.stackable.divided:not(.vertically).grid>.column:not(.row),
    .pq.stackable.celled.grid>.row>.column,
    .pq.stackable.divided:not(.vertically).grid>.row>.column {
        border-top: 1px solid rgba(34, 36, 38, 0.15);
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
    .pq.stackable.celled.grid>.row {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    .pq.stackable.divided:not(.vertically).grid>.column:not(.row),
    .pq.stackable.divided:not(.vertically).grid>.row>.column {
        padding-left: 0em !important;
        padding-right: 0em !important;
    }
}


/*----------------------
     Only (Device)
-----------------------*/


/* These include arbitrary class repetitions for forced specificity */


/* Mobile Only Hide */

@media only screen and (max-width: 767px) {
    .pq[class*="tablet only"].grid.grid.grid:not(.mobile),
    .pq.grid.grid.grid>[class*="tablet only"].row:not(.mobile),
    .pq.grid.grid.grid>[class*="tablet only"].column:not(.mobile),
    .pq.grid.grid.grid>.row>[class*="tablet only"].column:not(.mobile) {
        display: none !important;
    }
    .pq[class*="computer only"].grid.grid.grid:not(.mobile),
    .pq.grid.grid.grid>[class*="computer only"].row:not(.mobile),
    .pq.grid.grid.grid>[class*="computer only"].column:not(.mobile),
    .pq.grid.grid.grid>.row>[class*="computer only"].column:not(.mobile) {
        display: none !important;
    }
    .pq[class*="large screen only"].grid.grid.grid:not(.mobile),
    .pq.grid.grid.grid>[class*="large screen only"].row:not(.mobile),
    .pq.grid.grid.grid>[class*="large screen only"].column:not(.mobile),
    .pq.grid.grid.grid>.row>[class*="large screen only"].column:not(.mobile) {
        display: none !important;
    }
    .pq[class*="widescreen only"].grid.grid.grid:not(.mobile),
    .pq.grid.grid.grid>[class*="widescreen only"].row:not(.mobile),
    .pq.grid.grid.grid>[class*="widescreen only"].column:not(.mobile),
    .pq.grid.grid.grid>.row>[class*="widescreen only"].column:not(.mobile) {
        display: none !important;
    }
}


/* Tablet Only Hide */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pq[class*="mobile only"].grid.grid.grid:not(.tablet),
    .pq.grid.grid.grid>[class*="mobile only"].row:not(.tablet),
    .pq.grid.grid.grid>[class*="mobile only"].column:not(.tablet),
    .pq.grid.grid.grid>.row>[class*="mobile only"].column:not(.tablet) {
        display: none !important;
    }
    .pq[class*="computer only"].grid.grid.grid:not(.tablet),
    .pq.grid.grid.grid>[class*="computer only"].row:not(.tablet),
    .pq.grid.grid.grid>[class*="computer only"].column:not(.tablet),
    .pq.grid.grid.grid>.row>[class*="computer only"].column:not(.tablet) {
        display: none !important;
    }
    .pq[class*="large screen only"].grid.grid.grid:not(.mobile),
    .pq.grid.grid.grid>[class*="large screen only"].row:not(.mobile),
    .pq.grid.grid.grid>[class*="large screen only"].column:not(.mobile),
    .pq.grid.grid.grid>.row>[class*="large screen only"].column:not(.mobile) {
        display: none !important;
    }
    .pq[class*="widescreen only"].grid.grid.grid:not(.mobile),
    .pq.grid.grid.grid>[class*="widescreen only"].row:not(.mobile),
    .pq.grid.grid.grid>[class*="widescreen only"].column:not(.mobile),
    .pq.grid.grid.grid>.row>[class*="widescreen only"].column:not(.mobile) {
        display: none !important;
    }
}


/* Computer Only Hide */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .pq[class*="mobile only"].grid.grid.grid:not(.computer),
    .pq.grid.grid.grid>[class*="mobile only"].row:not(.computer),
    .pq.grid.grid.grid>[class*="mobile only"].column:not(.computer),
    .pq.grid.grid.grid>.row>[class*="mobile only"].column:not(.computer) {
        display: none !important;
    }
    .pq[class*="tablet only"].grid.grid.grid:not(.computer),
    .pq.grid.grid.grid>[class*="tablet only"].row:not(.computer),
    .pq.grid.grid.grid>[class*="tablet only"].column:not(.computer),
    .pq.grid.grid.grid>.row>[class*="tablet only"].column:not(.computer) {
        display: none !important;
    }
    .pq[class*="large screen only"].grid.grid.grid:not(.mobile),
    .pq.grid.grid.grid>[class*="large screen only"].row:not(.mobile),
    .pq.grid.grid.grid>[class*="large screen only"].column:not(.mobile),
    .pq.grid.grid.grid>.row>[class*="large screen only"].column:not(.mobile) {
        display: none !important;
    }
    .pq[class*="widescreen only"].grid.grid.grid:not(.mobile),
    .pq.grid.grid.grid>[class*="widescreen only"].row:not(.mobile),
    .pq.grid.grid.grid>[class*="widescreen only"].column:not(.mobile),
    .pq.grid.grid.grid>.row>[class*="widescreen only"].column:not(.mobile) {
        display: none !important;
    }
}


/* Large Screen Only Hide */

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
    .pq[class*="mobile only"].grid.grid.grid:not(.computer),
    .pq.grid.grid.grid>[class*="mobile only"].row:not(.computer),
    .pq.grid.grid.grid>[class*="mobile only"].column:not(.computer),
    .pq.grid.grid.grid>.row>[class*="mobile only"].column:not(.computer) {
        display: none !important;
    }
    .pq[class*="tablet only"].grid.grid.grid:not(.computer),
    .pq.grid.grid.grid>[class*="tablet only"].row:not(.computer),
    .pq.grid.grid.grid>[class*="tablet only"].column:not(.computer),
    .pq.grid.grid.grid>.row>[class*="tablet only"].column:not(.computer) {
        display: none !important;
    }
    .pq[class*="widescreen only"].grid.grid.grid:not(.mobile),
    .pq.grid.grid.grid>[class*="widescreen only"].row:not(.mobile),
    .pq.grid.grid.grid>[class*="widescreen only"].column:not(.mobile),
    .pq.grid.grid.grid>.row>[class*="widescreen only"].column:not(.mobile) {
        display: none !important;
    }
}


/* Widescreen Only Hide */

@media only screen and (min-width: 1920px) {
    .pq[class*="mobile only"].grid.grid.grid:not(.computer),
    .pq.grid.grid.grid>[class*="mobile only"].row:not(.computer),
    .pq.grid.grid.grid>[class*="mobile only"].column:not(.computer),
    .pq.grid.grid.grid>.row>[class*="mobile only"].column:not(.computer) {
        display: none !important;
    }
    .pq[class*="tablet only"].grid.grid.grid:not(.computer),
    .pq.grid.grid.grid>[class*="tablet only"].row:not(.computer),
    .pq.grid.grid.grid>[class*="tablet only"].column:not(.computer),
    .pq.grid.grid.grid>.row>[class*="tablet only"].column:not(.computer) {
        display: none !important;
    }
}


/*******************************
         Theme Overrides
*******************************/


/*******************************
         Site Overrides
*******************************/