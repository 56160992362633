.pq-flow-article-cards * {
    box-sizing: border-box;
}

.pq-flow-article-cards {
    position: relative;
}

.pq-flow-article-cards {
    padding-bottom: 1px;
    margin: 20px 0;
}

.pq-flow-article-cards:after {
    content: " ";
    display: block;
    padding-bottom: 1px;
    width: 100%;
    clear: both;
}

.pq-flow-article-cards .card {
    width: 100%;
    display: block;
    position: relative;
    padding-right: 20px;
    margin-bottom: 20px;
    vertical-align: top;
    float: left;
}

.pq-flow-article-cards .card .card-inner {
    padding-bottom: 100%!important;
    position: relative;
    box-shadow: 0 0 8px #cecece;
}

.pq-flow-article-cards .card .card-inner:hover {
    box-shadow: 0 0 12px #666;
}

.pq-flow-article-cards .card .cover {
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #eee;
    position: absolute;
    left: 0;
    top: 0;
    height: 67%;
    width: 100%;
    overflow: hidden;
    transition: transform .6s ease;
}

.pq-flow-article-cards .card .cover-tag {
    position: absolute;
    top: 6px;
    left: 6px;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    background-color: #000;
    color: white;
    box-shadow: 0 0 0 1px #000, 6px 0 0 1px #000;
    padding: 0 8px;
}

.pq-flow-article-cards .card .info-box {
    color: black;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4px 10px 24px;
    height: 32.98%;
    width: 100%;
}

.pq-flow-article-cards .info-box .flex-title-wrap {
    display: flex;
    align-items: center;
    height: 100%;
}

.pq-flow-article-cards .info-box .title {
    line-height: 22px;
    max-height: 44px;
    white-space: normal;
    font-size: 16px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    color: black;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.pq-flow-article-cards .info-box .fake-link:hover,
.pq-flow-article-cards .info-box a.title:hover {
    color: #ff9300;
}

.pq-flow-article-cards .info-box .bottom-bar {
    width: 100%;
    font-size: 12px;
    position: absolute;
    height: 20px;
    line-height: 20px;
    padding: 0 10px;
    bottom: 4px;
    left: 0;
    vertical-align: top;
    color: #888;
}

.pq-flow-article-cards .info-box .bottom-bar .icon {
    font-size: 12px;
    margin-right: 5px;
    vertical-align: top;
}

.pq-flow-article-cards .info-box .bottom-bar .left {
    float: left;
    display: inline-flex;
    align-items: center;
}

.pq-flow-article-cards .info-box .bottom-bar .right {
    float: right;
    display: inline-flex;
    align-items: center;
}


/* PC端样式 */

@media screen and (min-width: 840px) {
    .pq-flow-article-cards .card {
        width: 50%;
    }
}

@media screen and (min-width: 1040px) {
    .pq-flow-article-cards .card {
        width: 33.33%;
    }
    .pq-flow-article-cards.max-two-columns .card {
        width: 50%;
    }
}

@media screen and (min-width: 1340px) {
    .pq-flow-article-cards .card {
        width: 25%;
    }
    .pq-flow-article-cards.max-two-columns .card {
        width: 50%;
    }
    .pq-flow-article-cards.max-three-columns .card {
        width: 33.33%;
    }
}


/* Pad样式 */

@media screen and (max-width: 840px) {
    .pq-flow-article-cards {
        margin: 10px 0;
    }
    .pq-flow-article-cards .card {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;
    }
    .pq-flow-article-cards .card .card-inner {
        padding-bottom: 70%!important;
    }
}


/* Mobile样式 */

@media screen and (max-width: 440px) {
    .pq-flow-article-cards .info-box .bottom-bar {
        bottom: 1px;
    }
}

@media screen and (min-width: 600px) and (max-width: 840px) {
    .pq-flow-article-cards {
        margin-right: 0;
    }
    .pq-flow-article-cards .card {
        width: 50%;
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
    .pq-flow-article-cards .info-box .title {
        font-size: 14px;
        line-height: 18px;
        max-height: 36px;
    }
}