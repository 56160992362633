html,
body {
    padding: 0;
    margin: 0;
    font-size: 14px;
    overflow-x: hidden;
    color: black;
    background: #f7f7f7;
    position: relative;
}

body {
    min-height: 100vh;
    min-width: 320px;
    overflow-x: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: "Microsoft YaHei", "黑体", "宋体", sans-serif;
}

div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
dl,
dt,
dd,
ol,
ul,
li {
    border: 0;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

ol,
ul {
    list-style: none;
}

a {
    text-decoration: none;
}

a.block {
    display: block;
    color: inherit;
}

a.pq-link,
span.pq-link {
    color: black;
    cursor: pointer;
}

a.pq-link.white,
span.pq-link.white {
    color: white;
}

a.pq-link:hover,
span.pq-link:hover {
    color: #ff9300!important;
}

span._only_pc,
span._only_mobile {
    display: none;
}

.pq-no-margin {
    margin: 0!important;
}

.pq-ml8 {
    margin-left: 8px!important;
}

.pq-fr {
    float: right!important;
}

.pq-w200 {
    width: 200px!important;
}

.pq-responsive-padding {
    padding: 1rem;
}

.pq-reponsive-center-500 {
    position: relative;
    max-width: 500px;
    margin: 0 auto;
}

.pq-full-screen {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    overflow-y: hidden;
}

.pq-flexbox {
    display: flex;
    align-items: center;
}

.pq-absolute {
    position: absolute;
}

.pq-write-bg {
    background-color: white;
}

.pq-layout {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.pq-layout.has_background {
    background-color: #111;
}

.pq-layout>.pq-content {
    position: relative;
    margin: 0 auto;
}

.pq-layout>.pq-content.pq-no-margin {
    width: 100%!important;
}

.pq-layout>.pq-content.main {
    min-height: 400px;
    transition: transform 0.4s;
}

.pq-layout>.mobile-nav-dimmer {
    position: fixed;
    top: 0;
    left: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: opacity .4s, transform 0s .4s;
}

.pq-layout>.mobile-nav {
    position: fixed;
    top: 0;
    width: 260px;
    left: -260px;
    height: 100%;
    background: #171f26;
    transition: transform .4s;
    font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
}

.pq-layout>.mobile-nav .split {
    display: block;
    padding-bottom: 1px;
    border-bottom: 1px solid #444;
    box-sizing: border-box;
    margin: 10px 0;
}

.pq-layout>.mobile-nav .site-logo {
    display: flex;
    align-items: center;
}

.pq-layout>.mobile-nav .site-logo img {
    height: 40px;
    margin-right: 4px;
}

.pq-layout>.mobile-nav .site-title {
    color: rgb(255, 114, 41);
    font-size: 18px;
    font-weight: bold;
}

.pq-layout>.mobile-nav .mobile-nav-inner {
    overflow-y: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 10px;
    padding-bottom: 20px;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
}

.pq-layout>.mobile-nav .icon {
    margin-right: 5px;
}

.pq-layout>.mobile-nav .mobile-nav-link {
    color: white;
    text-decoration: none;
    display: block;
    padding: 10px 0;
    padding-left: 24px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pq-layout>.mobile-nav .mobile-nav-link.primary {
    padding-left: 10px;
}

.pq-layout>.mobile-nav .mobile-nav-link:hover {
    color: #ff9300;
    background: #111;
}

.pq-layout>.mobile-nav .mobile-nav-title {
    color: white;
    font-weight: 700;
    padding: 12px 15px;
    line-height: 1;
    display: block;
    border-top: 1px solid #444;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
}

.pq-layout>.mobile-nav .mobile-nav-title .icon,
.pq-layout>.mobile-nav .mobile-nav-title .pq-icon {
    margin-right: 6px;
}

.pq-header-top {
    position: absolute;
    top: 0;
    height: 60px;
    width: 100%;
    z-index: 1;
    transition: transform 0.4s;
    box-sizing: border-box;
}

.pq-header-top.with-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2)
}

.pq-header-top.fixed {
    position: fixed;
}

.pg-header-space {
    height: 60px;
    width: 100%;
}

.pq-header {
    background: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pq-header .pq-icon {
    font-size: 18px;
    cursor: pointer;
}

.pq-header .logo {
    flex: 1;
    text-align: center;
    height: 60px;
    display: block;
}

.pq-header .logo img {
    height: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: top;
}

.pq-header .left {
    position: absolute;
    left: 20px;
}

.pq-header .right {
    position: absolute;
    top: 0;
    left: 220px;
    display: none;
}

.pq-header .user-info {
    position: absolute;
    right: 40px;
    display: none;
    font-size: 14px;
}

.pq-header .user-info img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
}

.pq-header .user-info a {
    color: white;
    display: inline-flex;
    align-items: center;
}

.pq-header .user-info .pq-icon {
    padding-right: 6px;
    vertical-align: top;
}

.pq-header .site-title {
    line-height: 60px;
    height: 60px;
    font-size: 20px;
    color: rgb(250, 114, 41);
    padding-left: 4px;
    font-weight: bold;
}

.pq-header .nav-item {
    font-size: 16px;
    height: 60px;
    display: inline-block;
    box-sizing: border-box;
    margin: 0 10px;
    padding: 10px 0;
    position: relative;
    font-weight: bold;
    cursor: pointer;
}

.pq-header .nav-item .icon {
    margin-right: 5px;
}

.pq-header .sub-nav-block {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    padding: 10px 0;
    border-left: 4px solid black;
    top: 60px;
    left: -10px;
    display: none;
    max-height: 0;
    /*transition: max-height 0.2s ease-in;*/
    animation: show_sub_nav_keyframes 0.4s;
    z-index: 999;
    overflow-y: auto;
}

.pq-header-wrap {}

.pq-header-wrap.fixed {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    top: 0;
}

.pq-header-wrap.fixed.for_scroll {
    top: -60px;
    transition: top 0.1s;
    transition-timing-function: linear;
}

@keyframes show_sub_nav_keyframes {
    from {
        max-height: 0;
    }
    to {
        max-height: 480px;
    }
}

.pq-header-wrap .pg-header-space {
    display: block;
}

.pq-header-wrap.header-title-show .pg-header-space {
    display: none;
}

.pq-header-wrap .pure_css_animated_background {
    display: none;
}

.pq-header-wrap.header-title-show .pure_css_animated_background {
    display: block;
}

.pq-header-wrap.fixed.show {
    top: 0;
}

.pq-header .nav-item:hover .sub-nav-block {
    display: inline-flex;
    max-height: 480px;
}

.pq-header .sub-nav-block ul {
    padding: 0 10px;
    display: block;
    float: left;
}

.pq-header .sub-nav-block li a {
    border-radius: 3px;
    display: block;
    font-size: 13px;
    height: auto;
    line-height: 160%;
    min-width: 200px;
    padding: 10px;
    position: relative;
    text-align: left;
}

.pq-header .nav-link {
    color: #fff;
    text-decoration: none;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    display: inline-block;
}

.pq-header .nav-link:hover,
.pq-header .nav-link.active {
    border-bottom: 4px solid #ff9300;
}

.pq-header .nav-link.active {
    font-weight: bold;
}

.pq-section-title {
    margin-top: 30px;
    margin-bottom: 18px;
    font-size: 24px
}

.pq-section-title .icon {
    margin-right: 6px;
}

.pq-footer {
    position: relative;
    padding-bottom: 20px;
    color: #fff;
    font-size: 14px;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
    text-align: center;
}

.pq-footer .qrcode_area {
    margin: 10px 0;
    display: inline-block;
    text-align: center;
}

.pq-footer .title {
    font-size: 24px;
    font-weight: bold;
    padding: 5px 20px;
}

.pq-footer .intro {
    padding: 5px 20px;
}

.pq-footer .copyright {
    padding: 5px 20px;
}

.pq-footer .icp_number {
    padding: 5px 20px;
}

.pq-footer .qr_code_footer {
    width: 160px;
    height: 178px;
    position: relative;
    text-align: center;
    display: inline-block;
}

.pq-footer .qr_code_qr_code_footer_img {
    width: 120px;
    height: 120px;
    padding: 10px;
}

.pq-index-categories {
    text-align: center;
    line-height: 50px;
    color: #787878;
    white-space: nowrap;
    overflow-y: auto;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pq-index-categories>a {
    padding: 0 13px;
    color: #787878;
}

.pq-index-categories>a:hover {
    color: #ff9300;
}

.pq-article-wrap {
    width: 100%;
    position: relative;
}

.pq-article-copyright.first-line {
    margin-top: 30px;
}

.pq-article-copyright {
    color: #fd7213;
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.pq-article-wrap .main-thumb {
    background: white;
}

.pq-article-wrap .main-thumb img {
    width: 100%;
    display: block;
}

.pq_article_qr_code {
    position: absolute;
    right: -190px;
    top: 0px;
    width: 140px;
    padding: 16px;
    border: 1px solid #d9dadc;
    background-color: #fff;
    word-wrap: break-word;
    word-break: break-all;
    display: none;
    text-align: center;
}

.pq_article_qr_code_img {
    width: 102px;
    height: 102px;
}

.pq-404-area {
    width: 100%;
    height: 500px;
}

.pq-header .user-info .user-panel {
    position: absolute;
    min-width: 100px;
    background-color: rgba(0, 0, 0, 0);
    top: 0;
    right: 0;
    text-align: center;
    display: none;
}

.pq-header .user-info:hover .user-panel {
    display: block;
}

.pq-header .user-info .user-panel a {
    display: block;
    padding: 8px;
    color: black;
}

.pq-header .user-info .user-panel a:hover {
    color: #ff9300;
    background: #eee;
}

.pq-header .user-info .user-panel:before {
    content: " ";
    display: block;
    width: 100%;
    height: 36px;
    opacity: 0;
}

.pq-header .user-info .user-panel-inner {
    width: 100%;
    background: white;
    padding: 0;
    color: black;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.2);
}

.index-video-wrapper {
    height: 100%;
    width: 100%;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
}

.index-video-wrapper .index-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -100;
    right: 0;
    bottom: 0;
    background-size: cover;
    overflow: hidden;
}

.index-video-wrapper video {
    display: block;
}


/* PC端样式 */

@media screen and (min-width: 841px) {
    .pq-layout>.pq-content {
        width: 800px;
    }
    .pq-layout>.pq-content.main {
        margin-top: 30px;
        margin-bottom: 80px;
    }
    .pq-header .left {
        display: none;
    }
    .pq-header .logo {
        flex: 0;
        margin-left: 20px;
    }
    .pq-header .right {
        display: block;
    }
    .pq-header .user-info {
        display: block;
    }
    .pq-footer {
        min-height: 200px;
    }
    .pq-article-wrap {
        max-width: 877px;
        margin: 0 auto;
    }
    span._only_pc {
        display: inline;
    }
}

@media screen and (min-width: 941px) {
    .pq-layout>.pq-content {
        width: 900px;
    }
}

@media screen and (min-width: 1041px) {
    .pq-layout>.pq-content {
        width: 1000px;
    }
}

@media screen and (min-width: 1141px) {
    .pq-layout>.pq-content {
        width: 1000px;
    }
    .pq_article_qr_code {
        display: block;
    }
}

@media screen and (min-width: 1341px) {
    .pq-layout>.pq-content {
        width: 1200px;
    }
}


/* Pad样式 */

@media screen and (max-width: 840px) {
    /*  .pq-layout.has_background {
    background-image: none!important;
  }*/
    .pq-section-title {
        font-size: 18px;
        margin-bottom: 10px;
        margin-left: 10px;
    }
    .pq-layout.mobile-nav-on .pq-header-top {
        transform: translateX(260px);
    }
    .pq-layout.mobile-nav-on .pq-content.main {
        transform: translateX(260px);
    }
    .pq-layout.mobile-nav-on>.mobile-nav-dimmer {
        width: 100%;
        opacity: .7;
        transform: translateX(-100%);
        transition: opacity .4s;
        z-index: 2;
    }
    .pq-layout.mobile-nav-on>.mobile-nav {
        transform: translateX(100%);
        overflow-y: hidden;
        z-index: 3;
    }
    .pq-header .sub-nav-block {
        display: none!important;
    }
    .pq-footer {
        min-height: 200px;
    }
    .pq-index-categories {
        display: none;
    }
    .pq-header .site-title {
        display: inline-block;
        padding-right: 20px;
    }
    .pq-responsive-padding {
        padding: 1rem 0;
    }
    .pq-responsive-padding.bothside {
        padding: 1rem 1rem;
    }
    span._only_mobile {
        display: inline;
    }
}


/* Mobile样式 */

@media screen and (max-width: 440px) {
    .pq-article-copyright {
        font-size: 14px;
    }
    .pq-article-wrap .main-thumb {
        padding: 10px;
        padding-bottom: 0;
    }
}