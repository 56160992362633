/*******************************
            Message
*******************************/

.pq.message {
    position: relative;
    min-height: 1em;
    margin: 1em 0em;
    background: #F8F8F9;
    padding: 1em 1.5em;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
    transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease, -webkit-box-shadow 0.1s ease;
    border-radius: 0.28571429rem;
    -webkit-box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.22) inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.22) inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.message:first-child {
    margin-top: 0em;
}

.pq.message:last-child {
    margin-bottom: 0em;
}


/*--------------
     Content
---------------*/


/* Header */

.pq.message .header {
    display: block;
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin: -0.14285714em 0em 0rem 0em;
}


/* Default font size */

.pq.message .header:not(.pq) {
    font-size: 1.14285714em;
}


/* Paragraph */

.pq.message p {
    opacity: 0.85;
    margin: 0.75em 0em;
}

.pq.message p:first-child {
    margin-top: 0em;
}

.pq.message p:last-child {
    margin-bottom: 0em;
}

.pq.message .header+p {
    margin-top: 0.25em;
}


/* List */

.pq.message .list:not(.pq) {
    text-align: left;
    padding: 0em;
    opacity: 0.85;
    list-style-position: inside;
    margin: 0.5em 0em 0em;
}

.pq.message .list:not(.pq):first-child {
    margin-top: 0em;
}

.pq.message .list:not(.pq):last-child {
    margin-bottom: 0em;
}

.pq.message .list:not(.pq) li {
    position: relative;
    list-style-type: none;
    margin: 0em 0em 0.3em 1em;
    padding: 0em;
}

.pq.message .list:not(.pq) li:before {
    position: absolute;
    content: '•';
    left: -1em;
    height: 100%;
    vertical-align: baseline;
}

.pq.message .list:not(.pq) li:last-child {
    margin-bottom: 0em;
}


/* Icon */

.pq.message>.icon {
    margin-right: 0.6em;
}


/* Close Icon */

.pq.message>.close.icon {
    cursor: pointer;
    position: absolute;
    margin: 0em;
    top: 0.78575em;
    right: 0.5em;
    opacity: 0.7;
    -webkit-transition: opacity 0.1s ease;
    transition: opacity 0.1s ease;
}

.pq.message>.close.icon:hover {
    opacity: 1;
}


/* First / Last Element */

.pq.message> :first-child {
    margin-top: 0em;
}

.pq.message> :last-child {
    margin-bottom: 0em;
}


/*******************************
            Coupling
*******************************/

.pq.dropdown .menu>.message {
    margin: 0px -1px;
}


/*******************************
            States
*******************************/


/*--------------
    Visible
---------------*/

.pq.visible.visible.visible.visible.message {
    display: block;
}

.pq.icon.visible.visible.visible.visible.message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}


/*--------------
     Hidden
---------------*/

.pq.hidden.hidden.hidden.hidden.message {
    display: none;
}


/*******************************
            Variations
*******************************/


/*--------------
    Compact
---------------*/

.pq.compact.message {
    display: inline-block;
}

.pq.compact.icon.message {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}


/*--------------
    Attached
---------------*/

.pq.attached.message {
    margin-bottom: -1px;
    border-radius: 0.28571429rem 0.28571429rem 0em 0em;
    -webkit-box-shadow: 0em 0em 0em 1px rgba(34, 36, 38, 0.15) inset;
    box-shadow: 0em 0em 0em 1px rgba(34, 36, 38, 0.15) inset;
    margin-left: -1px;
    margin-right: -1px;
}

.pq.attached+.pq.attached.message:not(.top):not(.bottom) {
    margin-top: -1px;
    border-radius: 0em;
}

.pq.bottom.attached.message {
    margin-top: -1px;
    border-radius: 0em 0em 0.28571429rem 0.28571429rem;
    -webkit-box-shadow: 0em 0em 0em 1px rgba(34, 36, 38, 0.15) inset, 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
    box-shadow: 0em 0em 0em 1px rgba(34, 36, 38, 0.15) inset, 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
}

.pq.bottom.attached.message:not(:last-child) {
    margin-bottom: 1em;
}

.pq.attached.icon.message {
    width: auto;
}


/*--------------
      Icon
---------------*/

.pq.icon.message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pq.icon.message>.icon:not(.close) {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    line-height: 1;
    vertical-align: middle;
    font-size: 3em;
    opacity: 0.8;
}

.pq.icon.message>.content {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    vertical-align: middle;
}

.pq.icon.message .icon:not(.close)+.content {
    padding-left: 0rem;
}

.pq.icon.message .circular.icon {
    width: 1em;
}


/*--------------
    Floating
---------------*/

.pq.floating.message {
    -webkit-box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.22) inset, 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
    box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.22) inset, 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
}


/*--------------
     Colors
---------------*/

.pq.black.message {
    background-color: #1B1C1D;
    color: rgba(255, 255, 255, 0.9);
}


/*--------------
     Types
---------------*/


/* Positive */

.pq.positive.message {
    background-color: #FCFFF5;
    color: #2C662D;
}

.pq.positive.message,
.pq.attached.positive.message {
    -webkit-box-shadow: 0px 0px 0px 1px #A3C293 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #A3C293 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.positive.message .header {
    color: #1A531B;
}


/* Negative */

.pq.negative.message {
    background-color: #FFF6F6;
    color: #9F3A38;
}

.pq.negative.message,
.pq.attached.negative.message {
    -webkit-box-shadow: 0px 0px 0px 1px #E0B4B4 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #E0B4B4 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.negative.message .header {
    color: #912D2B;
}


/* Info */

.pq.info.message {
    background-color: #F8FFFF;
    color: #276F86;
}

.pq.info.message,
.pq.attached.info.message {
    -webkit-box-shadow: 0px 0px 0px 1px #A9D5DE inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #A9D5DE inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.info.message .header {
    color: #0E566C;
}


/* Warning */

.pq.warning.message {
    background-color: #FFFAF3;
    color: #573A08;
}

.pq.warning.message,
.pq.attached.warning.message {
    -webkit-box-shadow: 0px 0px 0px 1px #C9BA9B inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #C9BA9B inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.warning.message .header {
    color: #794B02;
}


/* Error */

.pq.error.message {
    background-color: #FFF6F6;
    color: #9F3A38;
}

.pq.error.message,
.pq.attached.error.message {
    -webkit-box-shadow: 0px 0px 0px 1px #E0B4B4 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #E0B4B4 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.error.message .header {
    color: #912D2B;
}


/* Success */

.pq.success.message {
    background-color: #FCFFF5;
    color: #2C662D;
}

.pq.success.message,
.pq.attached.success.message {
    -webkit-box-shadow: 0px 0px 0px 1px #A3C293 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #A3C293 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.success.message .header {
    color: #1A531B;
}


/* Colors */

.pq.inverted.message,
.pq.black.message {
    background-color: #1B1C1D;
    color: rgba(255, 255, 255, 0.9);
}

.pq.red.message {
    background-color: #FFE8E6;
    color: #DB2828;
    -webkit-box-shadow: 0px 0px 0px 1px #DB2828 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #DB2828 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.red.message .header {
    color: #c82121;
}

.pq.orange.message {
    background-color: #FFEDDE;
    color: #F2711C;
    -webkit-box-shadow: 0px 0px 0px 1px #F2711C inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #F2711C inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.orange.message .header {
    color: #e7640d;
}

.pq.yellow.message {
    background-color: #FFF8DB;
    color: #B58105;
    -webkit-box-shadow: 0px 0px 0px 1px #B58105 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #B58105 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.yellow.message .header {
    color: #9c6f04;
}

.pq.olive.message {
    background-color: #FBFDEF;
    color: #8ABC1E;
    -webkit-box-shadow: 0px 0px 0px 1px #8ABC1E inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #8ABC1E inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.olive.message .header {
    color: #7aa61a;
}

.pq.green.message {
    background-color: #E5F9E7;
    color: #1EBC30;
    -webkit-box-shadow: 0px 0px 0px 1px #1EBC30 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #1EBC30 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.green.message .header {
    color: #1aa62a;
}

.pq.teal.message {
    background-color: #E1F7F7;
    color: #10A3A3;
    -webkit-box-shadow: 0px 0px 0px 1px #10A3A3 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #10A3A3 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.teal.message .header {
    color: #0e8c8c;
}

.pq.blue.message {
    background-color: #DFF0FF;
    color: #2185D0;
    -webkit-box-shadow: 0px 0px 0px 1px #2185D0 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #2185D0 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.blue.message .header {
    color: #1e77ba;
}

.pq.violet.message {
    background-color: #EAE7FF;
    color: #6435C9;
    -webkit-box-shadow: 0px 0px 0px 1px #6435C9 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #6435C9 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.violet.message .header {
    color: #5a30b5;
}

.pq.purple.message {
    background-color: #F6E7FF;
    color: #A333C8;
    -webkit-box-shadow: 0px 0px 0px 1px #A333C8 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #A333C8 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.purple.message .header {
    color: #922eb4;
}

.pq.pink.message {
    background-color: #FFE3FB;
    color: #E03997;
    -webkit-box-shadow: 0px 0px 0px 1px #E03997 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #E03997 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.pink.message .header {
    color: #dd238b;
}

.pq.brown.message {
    background-color: #F1E2D3;
    color: #A5673F;
    -webkit-box-shadow: 0px 0px 0px 1px #A5673F inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #A5673F inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.pq.brown.message .header {
    color: #935b38;
}


/*--------------
     Sizes
---------------*/

.pq.mini.message {
    font-size: 0.78571429em;
}

.pq.tiny.message {
    font-size: 0.85714286em;
}

.pq.small.message {
    font-size: 0.92857143em;
}

.pq.message {
    font-size: 1em;
}

.pq.large.message {
    font-size: 1.14285714em;
}

.pq.big.message {
    font-size: 1.28571429em;
}

.pq.huge.message {
    font-size: 1.42857143em;
}

.pq.massive.message {
    font-size: 1.71428571em;
}


/*******************************
         Theme Overrides
*******************************/


/*******************************
        Site Overrides
*******************************/