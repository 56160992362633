.pq-article-exhibition {
    display: flex;
}

.pq-article-exhibition .big-part {
    width: 68.3%;
}

.pq-article-exhibition .small-part {
    width: 31.7%;
}

.pq-article-exhibition .item {
    position: relative;
    box-shadow: 0 0 8px #cecece;
    padding-bottom: 60%;
    height: 0;
}

.pq-article-exhibition .item:hover {
    box-shadow: 0 0 12px #666;
}

.pq-article-exhibition .item .cover {
    width: 100%;
    height: 100%;
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.pq-article-exhibition .item .poster-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 20%, rgba(0, 0, 0, 0) 40%);
}

.pq-article-exhibition .item .poster {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    max-height: 60%;
    margin: 0;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    color: white;
    overflow: hidden;
}

.pq-article-exhibition .big-part .poster {
    margin-bottom: 10px;
}

.pq-article-exhibition .poster h3,
.pq-article-exhibition .poster a.h3 {
    display: block;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 5px;
}

.pq-article-exhibition .poster p,
.pq-article-exhibition .poster a.p {
    display: block;
    font-size: 14px;
}

.pq-article-exhibition .small-part .poster p,
.pq-article-exhibition .small-part .poster a.p {
    font-weight: 400;
}

.pq-article-exhibition .poster a:hover,
.pq-article-exhibition .poster .fake-link:hover {
    color: #ff9300;
}


/* PC窄屏样式 */

@media screen and (min-width: 840px) {
    .pq-article-exhibition {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 20px;
    }
    .pq-article-exhibition .big-part {
        padding-right: 2%;
        box-sizing: border-box;
    }
    .pq-article-exhibition .small-part .item.first {
        margin-bottom: 5.5%;
    }
    .pq-article-exhibition .big-part .poster {
        padding-bottom: 10px;
    }
    .pq-article-exhibition .big-part .poster h3,
    .pq-article-exhibition .big-part .poster a.h3 {
        margin-bottom: 10px;
    }
}


/* PC端样式 */

@media screen and (min-width: 1040px) {}


/* Pad样式 */

@media screen and (max-width: 840px) {
    .pq-article-exhibition {
        display: block;
        padding: 0;
    }
    .pq-article-exhibition .item {
        margin: 0;
        box-shadow: none!important;
    }
    .pq-article-exhibition .big-part {
        min-width: 100%;
    }
    .pq-article-exhibition .big-part .item {
        padding-bottom: 50%;
    }
    .pq-article-exhibition .small-part {
        display: flex;
        min-width: 100%;
    }
    .pq-article-exhibition .small-part .item.first {
        margin-right: 10px;
    }
    .pq-article-exhibition .small-part .item {
        width: 50%;
        padding-bottom: 33.333%;
        margin-top: 10px;
    }
}


/* Mobile样式 */

@media screen and (max-width: 440px) {
    .pq-article-exhibition .poster h3,
    .pq-article-exhibition .poster a.h3 {
        font-size: 18px;
    }
    .pq-article-exhibition .poster p,
    .pq-article-exhibition .poster a.p {
        font-size: 12px;
    }
}